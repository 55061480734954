import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import Swal from 'sweetalert2'
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppToastServiceService } from '../services/app-toast-service.service';
import { CompressImageServiceService } from '../services/img-compressor/compress-image-service.service';
import { filter, pairwise, take } from 'rxjs/operators';
import { EncrDecrServiceService } from '../services/encr-decr-service.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

export class SignupComponent implements OnInit {
  @ViewChild('aadharotp') usernameElement: ElementRef;
  formData;
  // formDataA = new FormData;
  formData1;
  user_mail;
  user_id;
  entered_pan: any;
  entered_adhar: any;
  user_verification = 0;
  generated_otp: any;
  disableEmailBlock = 0;

  page = 1;
  error = 0;
  uploadedFiles: Array<File>;
  @ViewChild('userInput') userInputViewChild: ElementRef;

  userInputElement: HTMLInputElement;
  selectedFile: any;
  username: any;
  name: number;
  // aadharno:any;
  // errMsg
  reader;
  aadharreqid: any;
  disableBtn = false;
  user_gender: any;
  user_country: any;
  user_dob: any;
  Verification: any
  disableBlock: any
  otpmsg: any;
  loader: any = 1;
  load: any = 1;
  loading: any = 1;

  constructor(private actRoute: ActivatedRoute, private EncrDecr: EncrDecrServiceService,
    private compressImage: CompressImageServiceService, public toastService: AppToastServiceService,
    private fb: FormBuilder, private mysqlService: MysqlserviceService, private route: Router, private http: HttpClient) {

  }

  ngOnInit(): void {


    this.uploadedFiles = [];
    this.reader = new FileReader();
    this.formData = new FormData;
    this.formData1 = new FormData;

    this.regi2.controls['adhaar_front_pic'].disable();
    this.regi2.controls['adhaar_back_pic'].disable();
    this.regi.controls['password'].disable();
    this.Verification = 1
    this.otpmsg = 1;
    this.disableBlock = 1
    let email = localStorage.getItem("email")
    let arr = [{ email: email }]
    this.mysqlService.getUserId(arr).subscribe(a => {
      // console.log('response from get userid ' + JSON.stringify(a))
      if (a[0].aadharcard_status == 0 && a[0].pancard_status == 0) {
        this.page = 2;
      }
      else if (a[0].aadharcard_status == 1 && a[0].pancard_status == 0) {
        this.page = 3;
      }
      else {
      }
    }, (e) => {
      Swal.fire({
        title: 'Error',
        text: 'Server Error, Try again later',
        icon: 'error',
        confirmButtonText: 'Cancel',
        width: '400'
      })
    });
  }

  regi = this.fb.group({
    f_name: ['', [Validators.required]],
    email: ['', [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    check: ['', [Validators.required]],
    user_otp: ['', [Validators.required]]
  });

  regi2 = this.fb.group({
    adhaarNo: ['', [Validators.minLength(12), Validators.maxLength(12)]],
    adhaar_front_pic: ['', [Validators.required]],
    adhaar_back_pic: ['', [Validators.required]],
    otp: ['', [Validators.required]]

  })

  regi3 = this.fb.group({
    pancardNo: ['', [Validators.minLength(10), Validators.maxLength(10)]],
    pan_pic: ['', [Validators.required]],

  })
  public errMsg = {
    f_name: [
      { type: 'required', message: 'This field can not be blank' },
    ],
    email: [
      { type: 'required', message: 'Please enter a valid email' },
      { type: 'pattern', message: 'Please enter valid email' }
    ],
    password: [
      { type: 'required', message: 'This field cannot be blank' },
      { type: 'minlength', message: 'Minimum 6 digit password required' }
    ],
    check: [
      { type: 'required', message: 'Please accept the Terms' },
    ],

    adhaarNo: [
      { type: 'required', message: 'This field cannot be blank' },
      { type: 'maxlength', message: 'Aadhar number should be 12 digits' },
      { type: 'minlength', message: 'Aadhar number should be 12 digits' },
    ],
    pancardNo: [
      { type: 'required', message: 'This field cannot be blank' },
      { type: 'maxlength', message: 'Pan number should be 10 digits' },
      { type: 'minlength', message: 'Pan number should be 10 digits' },
    ],
    adhaar_front_pic: [
      { type: 'required', message: 'This field cannot be blank' },
    ],
    adhaar_back_pic: [
      { type: 'required', message: 'This field cannot be blank' },
    ],
    pan_pic: [
      { type: 'required', message: 'This field cannot be blank' },
    ],
    otp: [
      { type: 'required', message: 'This field cannot be blank' }

    ],
    user_otp: [
      { type: 'required', message: 'This field cannot be blank' }
    ]
  };

  onSubmit1() {
    // console.warn(this.regi2.value);
  }

  verify() {
    this.load = 0;
    var data = [{
      ano: this.regi2.value.adhaarNo,
    }]

    this.mysqlService.reqAdhar(data).subscribe((res: any) => {
      this.aadharreqid = res.request_id
      if (res.success == true || res.response_message == "Valid Authentication") {
        this.Verification = 2
        this.load = 1;
      }
      else {
        this.load = 1
        this.error = 3
      }
      // this.error = 2;    //use this when need to check all status code
    }, (e) => {
      this.load = 1
      Swal.fire({
        title: 'Error',
        text: 'Server Error, Try again later',
        icon: 'error',
        confirmButtonText: 'Cancel',
        width: '400'
      })
    })
  }
  verify_email() {
    this.generated_otp = Math.floor(1000 + Math.random() * 9000);
    // console.log(this.generated_otp);

    var current_email = this.regi.value.email;
    // console.log(current_email)
    // var mail_data=[{user_email:current_email,userOtp:generated_otp}]

    //------check email already exist or  not-------------

    let arr = [{ email: this.regi.value.email }]
    // console.log("Arr" + arr);

    this.mysqlService.getUserId(arr).subscribe((ressss: any) => {
      if (ressss == '') {
        this.mysqlService.validateEmail(current_email, this.generated_otp).subscribe(res => {
          // this.toastService.show('OTP sent on your mail id')
          this.toastService.showSuccess("OTP sent on your mail id")
          // console.log(res)
          this.user_verification = 1;
        })
      }
      else {
        Swal.fire({
          title: 'Error',
          text: 'Mail id already exists',
          icon: 'error',
          confirmButtonText: 'Cancel',
          width: '400'
        })
      }
    });
  }
  verify_user_otp()//for registeration
  {
    var user_entered_otp = this.regi.value.user_otp
    // console.log("otppp", user_entered_otp)
    if (this.generated_otp == user_entered_otp) {
      // this.toastService.showSuccess('OTP verified successfully')
      this.regi.controls['password'].enable();
      this.disableEmailBlock = 1;
    }
    else {
      Swal.fire({
        title: 'Error',
        text: 'Please enter valid OTP',
        icon: 'error',
        confirmButtonText: 'Cancel',
        width: '400'
      })
    }
  }


  verifyotp() //for adhar
  {
    this.loading = 0;
    let arr2 = [{
      reqid: this.aadharreqid,
      aadharotp: this.usernameElement.nativeElement.value
    }]

    this.mysqlService.resAdhar(arr2).subscribe((res: any) => {
      // console.log('response adhar '+JSON.stringify(res))
      if (res.success == false) {
        this.error = 1;
        this.otpmsg = 0;
        this.loading = 1;
      }
      if (res.response_message == "Invalid ID Number or Combination of Inputs" || res.response_code == '106') {
        this.loading = 1;
        Swal.fire({
          title: 'Error',
          text: 'Please enter valid OTP',
          icon: 'error',
          confirmButtonText: 'Cancel',
          width: '400'
        })
      }
      else {
        console.log('jhdffffffffffffffk' + this.usernameElement.nativeElement.value)
        this.regi2.controls['adhaar_front_pic'].enable();
        this.regi2.controls['adhaar_back_pic'].enable();
        this.disableBlock = 0;
        this.loading = 1;
        // this.toastService.showSuccess('OTP verified successfully')
        this.user_gender = res.result.user_gender;
        this.user_dob = res.result.user_dob;
        this.user_country = res.result.user_address.country;
      }
    }, (e) => {
      this.loading = 1;
      Swal.fire({
        title: 'Error',
        text: 'Server Error, Try again later',
        icon: 'error',
        confirmButtonText: 'Cancel',
        width: '400'
      })
    })
  }

  another() {
    this.page = 1;
    if (localStorage.length != 0) {
      localStorage.removeItem('useremail')
      localStorage.removeItem('email')
      localStorage.removeItem('SeesionUser')
    }
    this.regi.reset();
    window.location.reload();
    this.route.navigateByUrl('/signup')
  }

  submit() {
    if (this.regi.value.email != ' ' && this.regi.value.f_name != ' ' && this.regi.value.check != ' ') {

      var encrypted_password = this.EncrDecr.set('123456$#@$^@1ERF', this.regi.value.password);

      let user_data = [{ f_name: this.regi.value.f_name, email: this.regi.value.email, password: encrypted_password, check: this.regi.value.check }]

      let arr = [{ email: this.regi.value.email }]

      this.mysqlService.getUserId(arr).subscribe((res2: any) => {
        // console.log('response from get userid 2' + JSON.stringify(res2))

        if (res2 == '') {
          this.mysqlService.addUser(user_data).subscribe(res => {

            this.toastService.showSuccess("Registration done successfully")
          }, (e) => {
            // console.log('error inserting users details' + JSON.stringify(e))
            Swal.fire({
              title: 'Error',
              text: 'Server Error, Try again later',
              icon: 'error',
              confirmButtonText: 'Cancel',
              width: '400'
            })
          }, () => {

            this.page = 2;
            this.mysqlService.sendMail(this.regi.value.f_name, this.regi.value.email).subscribe(a => {

              // console.log('mail sent success', this.regi.value.email)
            }, e => {
              // console.log("error sending mail" + JSON.stringify(e))
            })

          })
        }
        else {
          this.toastService.showError('Mail id already exists')
          // Swal.fire({
          //   title: 'Error',
          //   text: 'Mail id already exists',
          //   icon: 'error',
          //   confirmButtonText: 'Cancel',
          //   width: '400'
          // })
        }
      },
        error => {
          // console.log("add user error" + JSON.stringify(error))
          Swal.fire({
            title: 'Error',
            text: 'Registration Error',
            icon: 'error',
            confirmButtonText: 'Cancel',
            width: '400'
          })
        }, () => {

        })
    }
    else {
      Swal.fire({
        title: 'Error',
        text: 'Fill all the details',
        icon: 'error',
        confirmButtonText: 'Cancel',
        width: '400'
      })
    }
    localStorage.setItem('email', this.regi.value.email)
  }//end of submit


  loadImageFromDevice1(element: any) {
    // this.reader.readAsDataURL(element.target.files[0]);
    // this.reader.onload = (element: any) => {
    let image: File = element.target.files[0]
    // console.log(`Image size before compressed: ${image.size} bytes.`)

    this.compressImage.compress(image)
      .pipe(take(1))
      .subscribe(compressedImage => {
        // console.log(`Image size after compressed: ${compressedImage.size} bytes.`)
        this.formData.append("upload[]", compressedImage);
        // now you can do upload the compressed image 
      }, (e) => {
        // console.log("error compressing image")
      })
    // }
  };

  loadImageFromDevice2(element: any) {
    // this.reader.onload = (element: any) => {
    let image: File = element.target.files[0]
    // console.log(`Image size before compressed: ${image.size} bytes.`)

    this.compressImage.compress(image)
      .pipe(take(1))
      .subscribe(compressedImage => {
        // console.log(`Image size after compressed: ${compressedImage.size} bytes.`)
        this.formData.append("upload[]", compressedImage);
        // now you can do upload the compressed image 
      }, (e) => {
        // console.log("error compressing image adhar back")
      })
    // }
  };

  loadImageFromDevice3(element: any) {
    this.uploadedFiles = []

    // this.reader.onload = (element: any) => {
    let image: File = element.target.files[0]
    // console.log(`Image size before compressed: ${image.size} bytes.`)

    this.compressImage.compress(image)
      .pipe(take(1))
      .subscribe(compressedImage => {
        // console.log(`Image size after compressed: ${compressedImage.size} bytes.`)
        this.formData1.append("upload1[]", compressedImage);
        // now you can do upload the compressed image 
      }, (e) => {
        // console.log("error compressing image pan")
      })
    // }
    // this.reader.readAsDataURL(element.target.files[0]);
  };

  submit2() {
    this.loader = 0;
    this.regi2.controls['adhaar_front_pic'].disable();
    this.regi2.controls['adhaar_back_pic'].disable();
    this.user_mail = localStorage.getItem("SeesionUser")
    let ano = this.regi2.value.adhaarNo;
    let email = localStorage.getItem("email");
    let arr = [{ email: email }]

    this.mysqlService.getUserId(arr).subscribe((res2: any) => {

      this.user_id = res2[0].user_id

    }, (e) => {
      Swal.fire({
        title: 'Error',
        text: 'Server Error, Try again later',
        icon: 'error',
        confirmButtonText: 'Cancel',
        width: '400'
      })
    }, () => {

      this.mysqlService.uploadImg(this.user_id, this.formData)
        .subscribe(response => {
          // console.log("image uploaded")

        }, (e) => {
          Swal.fire({
            title: 'Error',
            text: 'upload valid image',
            icon: 'error',
            confirmButtonText: 'Cancel',
            width: '400'
          })
          this.loader = 1;
          this.regi2.controls['adhaar_front_pic'].enable();
          this.regi2.controls['adhaar_back_pic'].enable();
        }, () => {

          let data1 = [{ user_id: this.user_id, aadharcard_no: ano, user_dob: this.user_dob, user_gender: this.user_gender, user_country: this.user_country }]

          this.mysqlService.addKycDetails(data1).subscribe((res) => {

          },
            (e) => {
              Swal.fire({
                title: 'Error',
                text: 'Server Error, Try again later',
                icon: 'error',
                confirmButtonText: 'Cancel',
                width: '400'
              })
              this.regi2.reset()
              this.uploadedFiles = [];
              this.reader = new FileReader();
              this.formData = new FormData;
              this.regi2.controls['adhaar_front_pic'].disable();
              this.regi2.controls['adhaar_back_pic'].disable();
              this.loader = 1;
              this.Verification = 1
              this.otpmsg = 1;
              this.disableBlock = 1
            }, () => {

              // let email;
              let emails = localStorage.getItem("email")
              // console.log("email" + emails)
              // console.log("aadhar number" + ano);

              var data = {
                // ano: this.regi2.value.adhaarNo,
                email: emails,
                aadharcard_status: 1
              }

              this.mysqlService.addaadharnumber(data).subscribe((a: any) => {
              }, (e) => {
                Swal.fire({
                  title: 'Error',
                  text: 'Please try again later',
                  icon: 'error',
                  confirmButtonText: 'Cancel',
                  width: '400'
                })
                this.regi2.reset()
                this.uploadedFiles = [];
                this.reader = new FileReader();
                this.formData = new FormData;
                this.regi2.controls['adhaar_front_pic'].disable();
                this.regi2.controls['adhaar_back_pic'].disable();
                this.loader = 1;
                this.Verification = 1
                this.otpmsg = 1;
                this.disableBlock = 1
              },
                () => {
                  //upload img to node
                  this.mysqlService.uploadImg(this.user_id, this.formData)
                    .subscribe(response => {
                      // console.log(response)
                    }, (e) => {
                      Swal.fire({
                        title: 'Error',
                        text: 'Server Error, Try again later',
                        icon: 'error',
                        confirmButtonText: 'Cancel',
                        width: '400'
                      })
                      this.regi2.reset()
                      this.uploadedFiles = [];
                      this.reader = new FileReader();
                      this.formData = new FormData;
                      this.regi2.controls['adhaar_front_pic'].disable();
                      this.regi2.controls['adhaar_back_pic'].disable();
                      this.loader = 1;
                      this.Verification = 1
                      this.otpmsg = 1;
                      this.disableBlock = 1
                    }, () => {

                    })
                  this.loader = 1;
                  this.page = 3;
                })
            })

        })

    })

  }

  submit3() {
    this.loader = 0;
    this.regi3.controls['pan_pic'].disable();
    this.regi3.controls['pancardNo'].disable();

    this.user_mail = localStorage.getItem("email")
    let pno;

    var data = {
      pno: this.regi3.value.pancardNo,
      email: this.user_mail,
      pancard_status: 1
    }
    let arr2 = [{ email: this.user_mail }]
    this.mysqlService.getUserId(arr2).subscribe((res2: any) => {
      this.user_id = res2[0].user_id
      localStorage.setItem('userid', this.user_id)

      this.mysqlService.getUsername(this.user_id).subscribe((ab) => {
      })

    }, (e) => {
      Swal.fire({
        title: 'Error',
        text: 'Server Error, Try again later',
        icon: 'error',
        confirmButtonText: 'Cancel',
        width: '400'
      })
      this.loader = 1;
      this.regi3.controls['pan_pic'].enable();
      this.regi3.controls['pancardNo'].enable();
    }, () => {

      this.mysqlService.reqPAN(data).subscribe((a: any) => {

        //checks pan is valid or not
        if (a.response_message == "Valid Authentication") {

          // if PAN valid checks username with PAN usename
          var panResponseName = a.result.user_full_name
          this.mysqlService.getUsers2(this.user_id).subscribe(e => {

            this.mysqlService.uploadPanImg(this.user_id, this.formData1)
              .subscribe(response => {
              }, (e) => {
                Swal.fire({
                  title: 'Error',
                  text: 'upload valid image',
                  icon: 'error',
                  confirmButtonText: 'Cancel',
                  width: '400'
                })
                this.loader = 1;
                this.regi3.controls['pan_pic'].enable();
                this.regi3.controls['pancardNo'].enable();
              }, () => {
                // var validationstatus = 1;
                var panno = this.regi3.value.pancardNo;

                if (panResponseName.toLowerCase() != e[0].f_name.toLowerCase()) {
                  Swal.fire({
                    title: 'Error',
                    text: 'Name does not match with PAN',
                    icon: 'warning',
                    confirmButtonText: 'Update',
                    width: '400'
                  }).then(() => {

                    this.route.navigateByUrl(`/signup2/${this.regi3.value.pancardNo}`)
                  })
                }

                else {
                  // ----------when pan is valid and name is matched successfully-----------------

                  //------------------------
                  let arr = [{ email: this.user_mail }]

                  this.mysqlService.getUserId(arr).subscribe((res2: any) => {
                    this.user_id = res2[0].user_id
                  }, (e) => {
                    Swal.fire({
                      title: 'Error',
                      text: 'Server Error, Try again later',
                      icon: 'error',
                      confirmButtonText: 'Cancel',
                      width: '400'
                    })
                    this.loader = 1;
                    this.regi3.controls['pan_pic'].enable();
                    this.regi3.controls['pancardNo'].enable();
                  }, () => {
                    //----------add pan number to kyc master----------------
                    let data2 = [{ pancard_no: this.regi3.value.pancardNo, user_id: this.user_id }]
                    this.mysqlService.addpannumber(data2).subscribe((a: any) => {
                    }, (e) => {
                      Swal.fire({
                        title: 'Error',
                        text: 'Please enter PAN number ',
                        icon: 'error',
                        confirmButtonText: 'Cancel',
                        width: '400'
                      })
                      this.loader = 1;
                      this.regi3.controls['pan_pic'].enable();
                      this.regi3.controls['pancardNo'].enable();
                    },
                      () => {

                        //--------------- update pan status ------------------------
                        var data3 = {
                          email: this.user_mail,
                          pancard_status: 1
                        }

                        this.mysqlService.updatepanstatus(data3).subscribe((a: any) => {
                        }, (e) => {
                          Swal.fire({
                            title: 'Error',
                            text: 'Server Error, Try again later',
                            icon: 'error',
                            confirmButtonText: 'Cancel',
                            width: '400'
                          })
                          this.loader = 1;
                          this.regi3.controls['pan_pic'].enable();
                          this.regi3.controls['pancardNo'].enable();
                        })

                        //-------------------- upload image -------------------------

                      })
                    this.route.navigateByUrl(`/login`)
                  })
                  //--------------------------
                }
              })
            // })
            // })
          }, (e) => {
            Swal.fire({
              title: 'Error',
              text: 'Server Error, Try again later',
              icon: 'error',
              confirmButtonText: 'Cancel',
              width: '400'
            })
            this.loader = 1;
            this.regi3.controls['pan_pic'].enable();
            this.regi3.controls['pancardNo'].enable();
          })
        }

        else {
          Swal.fire({
            title: 'Error',
            text: 'Invalid PAN',
            icon: 'error',
            confirmButtonText: 'Cancel',
            width: '400'
          })
          this.loader = 1;
          this.regi3.controls['pan_pic'].enable();
          this.regi3.controls['pancardNo'].enable();

        }
      },
        (e) => {
          Swal.fire({
            title: 'Error',
            text: 'Server Error, Try again later',
            icon: 'error',
            confirmButtonText: 'Cancel',
            width: '400'
          })
          this.loader = 1;
          this.regi3.controls['pan_pic'].enable();
          this.regi3.controls['pancardNo'].enable();
        }, () => {

        })
    })

  }
}