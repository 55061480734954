import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2'
import { MysqlserviceService } from '../../app/services/mysqlService/mysqlservice.service';
import * as schedule from 'node-schedule';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('id') transIdElement: ElementRef;
  inputIdstatus: any;
  inputId = 0;
  waitcss: any
  confirmcss: any;
  successcss: any;
  overduecss: any;
  successcssss: any;
  loginbtn: any = 0;
  logoutbtn: any = 0;
  x: any;

  noInternetConnection: boolean;

  timecheck: any;

  constructor(private mysql: MysqlserviceService) { }

  ngAfterViewInit() {
    this.waitcss = document.getElementById('wait_id1');
    this.confirmcss = document.getElementById('confirm_id1');
    this.successcss = document.getElementById('success_id1');
    this.overduecss = document.getElementById('overdue_id1');
    this.successcssss = document.getElementById('btntrack');
    // throw new Error('Method not implemented.');
  }

  ngOnInit() {
    let SeesionUser = localStorage.getItem("SeesionUser")
    // console.log("In app.ts file", SeesionUser);

    if (SeesionUser == null) {
      this.loginbtn = 0;
      this.logoutbtn = 1;
    }
    else {
      this.loginbtn = 1;
      this.logoutbtn = 0;
    }
  }
  ngAfterViewChecked() {
    // alert('in if=====')
    let SeesionUser = localStorage.getItem("SeesionUser")
    // console.log("In app.ts file",SeesionUser);

    if (SeesionUser == null) {
      this.loginbtn = 0;
    }
    else {
      this.loginbtn = 1;
    }

    if (this.inputId == 1) {
      // alert('in if')
      this.waitcss = document.getElementById('wait_id1');
      this.confirmcss = document.getElementById('confirm_id1');
      this.successcss = document.getElementById('success_id1');
      this.overduecss = document.getElementById('overdue_id1');
      this.successcssss = document.getElementById('btntrack');

      if (this.inputIdstatus == 'waiting') {
        this.waitcss.style.background = 'lawngreen'
        var bbb = new Date().toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
        if (new Date(this.timecheck).getTime() < new Date(bbb).getTime()) {
          this.waitcss.style.background = 'red';
          this.confirmcss.style.background = 'red';
          this.successcss.style.background = 'red';
          this.overduecss.style.background = 'red';
        }
      }
      if (this.inputIdstatus == 'confirming') {
        this.waitcss.style.background = 'lawngreen';
        this.confirmcss.style.background = 'lawngreen';
      }
      if (this.inputIdstatus == 'finished') {
        this.waitcss.style.background = 'lawngreen';
        this.confirmcss.style.background = 'lawngreen';
        this.successcss.style.background = 'lawngreen';
      }
      if (this.inputIdstatus == 'failed') {
        this.waitcss.style.background = 'red';
        this.confirmcss.style.background = 'red';
        this.successcss.style.background = 'red';
        this.overduecss.style.background = 'red';
      }


    }
    if (this.inputId == 2) {
      // alert('in if')
      this.waitcss = document.getElementById('wait_id1');
      this.successcss = document.getElementById('success_id1');
      this.overduecss = document.getElementById('overdue_id1');
      this.successcssss = document.getElementById('btntrack');

      if (this.inputIdstatus == 'waiting') {
        this.waitcss.style.background = 'lawngreen';
      }
      if (this.inputIdstatus == 'success') {
        this.waitcss.style.background = 'lawngreen';
        this.successcss.style.background = 'lawngreen';
      }
      if (this.inputIdstatus == 'failed') {
        this.waitcss.style.background = 'red';
        this.successcss.style.background = 'red';
        this.overduecss.style.background = 'red';
      }
    }

    if (this.inputId == 3) {
      this.waitcss = document.getElementById('wait_id1');
      this.successcss = document.getElementById('success_id1');
      this.overduecss = document.getElementById('overdue_id1');
      this.successcssss = document.getElementById('btntrack');

      if (this.inputIdstatus == 'waiting') {
        this.waitcss.style.background = 'lawngreen';
      }
      if (this.inputIdstatus == 'success') {
        this.waitcss.style.background = 'lawngreen';
        this.successcss.style.background = 'lawngreen';
      }
      if (this.inputIdstatus == 'failed') {
        this.waitcss.style.background = 'red';
        this.successcss.style.background = 'red';
        this.overduecss.style.background = 'red';
      }
    }
  }

  logout() {
    // console.log(localStorage.length)
    if (localStorage.length != 0) {
      localStorage.removeItem('useremail')
      localStorage.removeItem('SeesionUser')
      localStorage.removeItem('email')
      localStorage.removeItem('userid')
      // this.route.navigateByUrl('/login')
    }
    else {
      //  this.route.navigateByUrl('/login')
    }
    // this.route.navigateByUrl('/login')
  }

  submitTransacId() {
    // clearInterval(this.x)
    var data = { trans_id: this.transIdElement.nativeElement.value }
    this.mysql.getExchngeInfoSwapspace(data).subscribe((a: any) => {
      console.log('hiiiii')
      this.inputId = 1;

      this.inputIdstatus = a.status
      var e = a.timestamps.expiresAt + "Z"
      var dE: any = new Date(e).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });

      this.timecheck = dE
      this.x = setInterval(async () => {
        this.inputId = 0
      }, 4000);
    }, (e) => {
      var dataArr = { trans_id: this.transIdElement.nativeElement.value }

      this.mysql.getBigbTransactionStatusTrack(dataArr).subscribe((a: any) => {
        console.log('response getBigbTransactionStatus' + JSON.stringify(a))
        if (a.status == 'waiting') {
          this.inputIdstatus = 'waiting'
          this.inputId = 2;
          setInterval(async () => {
            this.inputId = 0
          }, 4000);
        }
        else if (a.status == 'success') {
          this.inputIdstatus = 'success'
          this.inputId = 2;
          setInterval(async () => {
            this.inputId = 0
          }, 4000);
        } else if (a.status == 'failed') {
          this.inputIdstatus = 'failed'
          this.inputId = 2;
          setInterval(async () => {
            this.inputId = 0
          }, 4000);
        }
        else if (a.status == 'invalid') {
          this.inputId = 0
          Swal.fire({
            title: 'Error',
            text: `Enter Valid Transaction Id`,
            icon: 'error',
            confirmButtonText: 'Ok',
            width: '400'
          })
        }
      }, (e) => {
        this.inputId = 0
        Swal.fire({
          title: 'Error',
          text: `Enter Valid Transaction Id`,
          icon: 'error',
          confirmButtonText: 'Ok',
          width: '400'
        })
      }, () => {
        // complete of transactionCoinpayment
      })
    }, () => {
      // complete of getExchngeInfoSwapspace

    })
  }

}