import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import Swal from 'sweetalert2'
import { AppToastServiceService } from '../services/app-toast-service.service';
import { EncrDecrServiceService } from '../services/encr-decr-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('passvalue') userElement: ElementRef;
  
  user = '1';
  getUserData_A: any = [];
  email: any;
  password: any;
  showToast: any = 0;
  encrypted:any;
email2: any;
  all_user_email:any=[];
  email_exist_or_not:boolean;
  mysqlService: any;
  disableBlock: any;
  generated_otp: any;
  disableEmailBlock = 0;
  user_verification = 0;
  password2: any=1;
  confirmpassword:any;
  data:any=[];
  user_id: any;
  all_user_data:any=[];

  constructor(private EncrDecr: EncrDecrServiceService,private mysql: MysqlserviceService, private fb: FormBuilder, private route: Router, private toastService: AppToastServiceService) { }

  ngOnInit(): void {

    this.disableBlock = 1;

    this.mysql.getUsers().subscribe((res: any) => {

      res.forEach(elements => {
        this.getUserData_A.push(elements);
        this.email = elements.email;
        this.password = elements.password;

         //make array of all user emails only
        this.all_user_email.push(elements.email)
        this.all_user_data.push(elements);
      })
      // console.log(this.getUserData_A)
      // console.log("emil--------------------",this.email)

      // console.log("user name", this.getUserData_A[0].f_name);
    },e =>{
      Swal.fire({
        title: 'Error',
        text: 'Server Error, Try again later',
        icon: 'error',
        confirmButtonText: 'cancel',
        width: '400'
      })
    })
  }

  regi = this.fb.group({
    email: ['', [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  forgot = this.fb.group({
    email2: ['', [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
    user_otp: ['', [Validators.required]],
    password2: ['', [Validators.required, Validators.minLength(6)]],
    confirmpassword: ['', [Validators.required, Validators.minLength(6)]],
    user_id : ['',Validators.required]
  });

  public errMsg = {
    email: [
      { type: 'required', message: 'This field cannot be blank' },
      { type: 'pattern', message: 'Please enter a valid email address' }
    ],
    password: [
      { type: 'required', message: 'This field cannot be blank' },
      { type: 'minlength', message: 'Minimum 6 digit password required' }
    ],
    email2: [
      { type: 'required', message: 'This field cannot be blank' },
      { type: 'pattern', message: 'Please enter a valid email address' }
    ],
    user_otp: [
      { type: 'required', message: 'This field cannot be blank' }
    ],
    password2: [
      { type: 'required', message: 'This field cannot be blank' },
      { type: 'pattern', message: 'Please enter a valid email address' }
    ],
    confirmpassword: [
      { type: 'required', message: 'This field cannot be blank' },
      { type: 'minlength', message: 'Minimum 6 digit password required' }

    ],
  }

// ---------------------------to send mail for OTP to reset password--------------------
  mail(){
    
    var mail2 = this.forgot.value.email2;
    
    this.all_user_data.forEach((res:any)=>{
      if(res.email == mail2){
        this.user_id = res.user_id;
        console.log("Response check",res.user_id);
      }
    })
    // console.log("Test email", mail2)

    this.generated_otp = Math.floor(1000 + Math.random() * 9000);

    if (this.all_user_email.includes(mail2)) {
      this.mysql.sendForgotMail(mail2,this.generated_otp).subscribe((a: any) => {
      this.user_verification = 1;
      
        this.toastService.showSuccess("OTP Sent on your email!")

      }, e => {
        console.log("error sending mail" + JSON.stringify(e))
      })
    }
    else{
      this.toastService.showError("Email not registered!");
    }
  }

  verify_user_otp()//for OTP 
  {
    var user_entered_otp = this.forgot.value.user_otp

    console.log("otppp", user_entered_otp)
    
    if (this.generated_otp == user_entered_otp) {
      this.toastService.showSuccess('OTP verified successfully')
      this.regi.controls['password'].enable();
     
      this.user_verification = 2;
    }
    else {
      Swal.fire({
        title: 'Error',
        text: 'Please enter valid OTP',
        icon: 'error',
        confirmButtonText: 'Cancel',
        width: '400'
      })
    }
  }

  // -------------------------------------------both password are same or not----------------
  checkpswd(){
    // if(this.regi.valid){
      if(this.forgot.value.password2 === this.userElement.nativeElement.value){
        console.log("Update success"+this.userElement.nativeElement.value)
        this.password2 = 1;
      }
      else{
        console.log("Wrong code.."+this.userElement.nativeElement.value);
        this.password2 = 0;
      }
    // }
  }

  // ---------------------------------update password----------------------------
  update(){
    if(this.forgot.value.password2 === this.userElement.nativeElement.value){
    var encrypted_password = this.EncrDecr.set('123456$#@$^@1ERF', this.forgot.value.password2);
    this.data = {
      password2: encrypted_password,
      user_id: this.user_id
    }

    console.log("Password",this.data)

    this.mysql.updatePassword(this.data).subscribe(res => {
      this.toastService.showSuccess("Updated successfully");
      this.forgot.reset();
      this.user_verification = 0;
      
      console.log("Response..",res);
    })
  }
    else{
      this.toastService.showError("Password does not match");
    }
  }


// ----------------------------------------login----------------------------

login(){
  var flag1 = 0
  this.showToast = 1;
    if(this.all_user_email.includes(this.regi.value.email))             //-----------------------------------if-1----check email
    {
      this.mysql.get_encrypted_password(this.regi.value.email).subscribe((response:any)=>{
        console.log(JSON.stringify(response))
        response.forEach(a => {
          this.encrypted=a[0].password
        })
           
        console.log("encrypted value : ",this.encrypted)
          var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', this.encrypted);
          // console.log("decrypted value : ",decrypted)
          // console.log("element.email",element.email)

        if ( this.regi.value.password == decrypted) {    //---------------------------------if-2----check password
          // console.log("in if condition")
          flag1 = 1
          // console.log("flag",flag1)
          let arr = [{ email:this.regi.value.email }]
          this.mysql.getUserId(arr).subscribe(a => {
  
            if (a[0].aadharcard_status == 0 && a[0].pancard_status == 0) {
  
              localStorage.setItem('email', this.regi.value.email);
              this.route.navigateByUrl('/signup');
  
            }
            else if (a[0].aadharcard_status == 1 && a[0].pancard_status == 0) {
  
              localStorage.setItem('email', this.regi.value.email);
              this.route.navigateByUrl('/signup');
  
            }
            else if (a[0].aadharcard_status == 1 && a[0].pancard_status == 1) {
              localStorage.setItem('SeesionUser', a[0].user_id)
              this.toastService.showSuccess("Logged in successfully")
              this.route.navigateByUrl('/view-table',{ state: { drp_1: 'BTC',drp_2: 'ETH' } });

            }
            else {
              this.route.navigateByUrl('/view-table',{ state: { drp_1: 'BTC',drp_2: 'ETH' } });
            }
          },
           (e) => {
             //---getUserId error code---
            Swal.fire({
              title: 'Error',
              text: 'Server Error, Try again later',
              icon: 'error',
              confirmButtonText: 'cancel',
              width: '400'
            })
          },
           () => {
              //---getUserId compelete code---

          })  //--getUserId subscribe
        }                                      //---------------------------------closing of if-2----check password
        else{
          Swal.fire({
                  title: 'Error',
                  text: 'Incorrect Password..! Please try again.',
                  icon: 'warning',
                  confirmButtonText: 'Ok',
                  width: '400'
                })
        }                                             
      })  //----------get_encrypted_password subscribe
    }
    else{                                                          //-----------------------else-1  //if email inavlid
      Swal.fire({
        title: 'Error',
        text: 'Email not registered..!! Please signup first.',
        icon: 'warning',
        confirmButtonText: 'Ok',
        width: '400'
      })
    }
    
} //end of login

}