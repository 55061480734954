<!DOCTYPE html>
<html lang="en">

<body>
    <app-header></app-header>
    <div class="user-login-signup-section modal-container">
        <div class="container">
            <div class="user-login-signup-form-wrap">
                <div class="modal-content">
                    <h3>User Login</h3>
                    <div class="modal-body">
                        <div class="modal-info-block">
                            <p>Always ensure you're on the correct website</p>
                            <div class="block-inner">
                                <p>
                                    <span>
                                        <i class="fas fa-lock"></i>
                                        https://
                                    </span> www.cotoex.com
                                </p>
                            </div>
                        </div>
                        <div class="user-connected-form-block">
                            <form class="user-connected-from user-login-form" [formGroup]="regi">
                                <div class="form-group">
                                    <input type="email" formControlName="email" class="form-control" required id="exampleInputEmail1" placeholder="Email address">

                                    <div *ngFor="let e of errMsg.email">
                                        <ng-container *ngIf="regi.get('email').touched && !regi.get('email').valid 
                                        && regi.get('email').hasError(e.type)">
                                            <small class="error-message" style="color: red;">{{e.message}}</small>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="password" formControlName="password" class="form-control" required id="exampleInputPassword1" placeholder="Password">

                                    <div *ngFor="let e of errMsg.password">
                                        <ng-container *ngIf="regi.get('password').touched && !regi.get('password').valid 
                                        && regi.get('password').hasError(e.type)">
                                            <small class="error-message" style="color: red;">{{e.message}}</small>
                                        </ng-container>
                                    </div>
                                </div>

                                <button type="submit" (click)="login()" class="btn btn-default">Login</button>

                            </form>
                            <p>Don't have an account? <a [routerLink]="['/signup']"> Register</a>
                                <a style="margin-left: 25%; color: blue; cursor: pointer;" data-toggle="modal" data-target="#myModal1" href="/forgot-password">
                                Forgot Password?
                            </a>
                            </p>
                            <div class="modal" id="myModal1">
                                <div class="modal-dialog">
                                    <div class="modal-content">

                                        <!-- Modal Header -->
                                        <div class="modal-header">
                                            <h4 class="modal-title">Forgot Password</h4>
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                        </div>

                                        <!-- Modal body -->
                                        <div class="modal-body">
                                            <div class="modal-content">
                                                <form class="user-connected-from user-login-form" [formGroup]="forgot">
                                                    <div class="form-group" *ngIf="disableEmailBlock==0">
                                                        <div class="info" *ngIf="user_verification==0">
                                                            <input type="email" placeholder="Enter your email" formControlName="email2" class="form-control">

                                                            <div *ngFor="let e of errMsg.email2">
                                                                <ng-container *ngIf="forgot.get('email2').touched && !forgot.get('email2').valid 
                                                        && forgot.get('email2').hasError(e.type)">
                                                                    <small class="error-message" style="color: red;">{{e.message}}</small>
                                                                </ng-container>
                                                            </div>


                                                            <div class="col-3" style="margin-top: 5px; margin-left: 20%;">
                                                                <button class="btn btn-info" style="font-weight: bold;border:1px solid gray;" data-toggle="modal" data-target="#exampleModal" (click)="mail()">Verify</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- for enter otp -->
                                                    <div class="form-group" *ngIf="user_verification==1">
                                                        <div class="info">

                                                            <input type="text" formControlName="user_otp" class="form-control" required id="user_otp2" placeholder="Enter OTP">

                                                            <div *ngFor="let e of errMsg.user_otp">
                                                                <ng-container *ngIf="forgot.get('user_otp').touched && !forgot.get('user_otp').valid 
                                            && forgot.get('user_otp').hasError(e.type)">
                                                                    <small class="error-message" style="color: red;">{{e.message}}</small>
                                                                </ng-container>
                                                            </div>

                                                            <div class="col-3" style="margin-top: 5px;">
                                                                <button class="btn btn-info" style="font-weight: bold;border:1px solid gray;" data-toggle="modal" data-target="#exampleModal" (click)="verify_user_otp()">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- forgot-password -->
                                                    <div class="modal-body">
                                                        <div class="modal-content" style="margin-left: 10%;">
                                                            <!-- <div class="user-connected-form-block"> -->
                                                            <!-- <form class="user-connected-from user-login-form" [formGroup]="for_password"> -->
                                                            <div class="form-group" *ngIf="user_verification==2">
                                                                <input type="password" formControlName="password2" class="form-control" required id="exampleInputEmail1" placeholder=" New Password">

                                                                <div *ngFor="let e of errMsg.password">
                                                                    <ng-container *ngIf="forgot.get('password2').touched && !forgot.get('password2').valid 
                                                        && forgot.get('password2').hasError(e.type)">
                                                                        <small class="error-message" style="color: red;">{{e.message}}</small>
                                                                    </ng-container>
                                                                </div>
                                                            </div>

                                                            <div class="form-group" *ngIf="user_verification==2">
                                                                <input type="password" formControlName="confirmpassword" class="form-control" required id="exampleInputPassword2" placeholder="Confirm Password" (input)="checkpswd()" #passvalue>

                                                                <div *ngFor="let e of errMsg.confirmpassword">

                                                                    <ng-container *ngIf="forgot.get('confirmpassword').touched && !forgot.get('confirmpassword').valid 
                                                        && forgot.get('confirmpassword').hasError(e.type)">
                                                                        <div>
                                                                            <small *ngIf='password2==1' class="error-message" style="color: red;">{{e.message}}</small>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <small *ngIf='password2==0' class="error-message" style="color: red;">Password does not match</small>

                                                                <div class="col-3" style="margin-top: 5px;">
                                                                    <button class="btn btn-info" style="font-weight: bold;border:1px solid gray;" data-toggle="modal" data-target="#exampleModal" (click)="update()">Reset Password</button>
                                                                </div>
                                                            </div>

                                                            <!-- </form> -->
                                                            <!-- </div> -->
                                                            <!-- create-account-block -->
                                                        </div>
                                                    </div>

                                                    <!-- Modal footer -->
                                                    <!-- <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                       </div> -->
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- create-account-block -->
                    </div>
                </div>
            </div>
            <!-- user-login-signup-form-wrap -->
        </div>
    </div>
    <app-footer></app-footer>
    <!-- <app-app-toast aria-live="polite" aria-atomic="true"></app-app-toast> -->


    <script src="./assets/js/jquery-1.12.4.min.js"></script>
    <script src="./assets/js/popper.min.js"></script>
    <script src="./assets/js/bootstrap.min.js"></script>
    <script src="./assets/js/slick.min.js"></script>
    <script src="./assets/js/jquery.peity.min.js"></script>
    <script src="./assets/js/jquery.slimscroll.min.js"></script>
    <script src="./js/custom.js"></script>

</body>

</html>