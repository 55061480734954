<nav class="navbar main-nav navbar-expand-lg">
    <div class="container">
        <a class="navbar-brand" href="index.html">
            <img class="navbar-logo" src="assets/COTOEX Logo_white and orange.png" alt="..." height="30px" width="160px"
                style="padding: -12%;">
        </a>
        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link" href='/dashboard'>Home</a></li>
                <li class="nav-item"><a class="nav-link" href='/how-it-works'>How it Works </a></li>
                <li class="nav-item"><a class="nav-link" href="/about">About</a></li>
                <!-- <li class="nav-item"><a class="nav-link" href='/faq'>FAQ</a></li> -->
                <!-- <li class="nav-item"><a class="nav-link" href="#">Contact Us</a></li> -->


                <li class="nav-item" *ngIf="loginbtn==0">
                    <a class="nav-link" href='/login'>Login</a>
                </li>
                <!-- <li class="nav-item"><a class="nav-link" href="https://www.bighbull.com/">Buy BIGH</a></li> -->
                <!-- <li class="nav-item">
                    <a class="nav-link" href='/signup'>Sign Up</a>
                </li> -->

                <li class="nav-item" data-toggle="modal" data-target="#myModal"><a class="nav-link" class="visible-xs"
                        data-toggle="collapse" data-target=".navbar-collapse"
                        style="color:red;font-weight: bold; cursor: pointer;">Track Exchange
                    </a></li>
                <!-- <li class="p" data-toggle="modal" data-target="#myModal">
                    Track your Transaction
                </li> -->

                <!-- <li class="nav-item" > -->

                <li class="nav-item" *ngIf="loginbtn==1">
                    <a (click)="logout()" class="nav-link" href='/login'><i class="fas fa-sign-out-alt"
                            style="font-size: 15px;"></i></a>
                </li>

            </ul>
        </div>
    </div>




    <!-- <div class="modal" id="myModal2">
            <div class="modal-dialog">
                
            </div>
        </div> -->


    <!-- Modal -->
</nav><!-- main-nav-block -->



<form>

    <div class="modal" id="myModal">
        <div class="modal-dialog">
            <div class="modal-content" *ngIf="inputId==0">

                <div class="modal-header">

                    <input type="text" class="form-control  " id="exampleInputPassword1" placeholder="Enter Exchange ID"
                        #id>
                    <!-- <h3 class="" *ngIf="input_id == 1">{{inputIdstatus}}</h3> -->



                </div>
                <button type="button" (click)="submitTransacId()" class="btn btn-primary">Track Your
                    Transaction</button>
            </div>
            
            <div class="modal-content modalss" id="btntrack" *ngIf="inputId==1">
                <h4 style="padding: 3%; color: white;font-weight: bold;"><b>Your Progress Status is : </b></h4>
                <div class="container ">
                    <div
                        class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                        <div class="step completed">
                            <div class="step-icon-wrap">
                                <div class="step-icon" id="wait_id1"><i class="fas fa-clock"></i>
                                </div>
                            </div>
                            <h4 class="step-title">Wait</h4>
                        </div>
                        <div class="step completed">
                            <div class="step-icon-wrap">
                                <div class="step-icon" id="confirm_id1"><i class="fas fa-check-circle"></i></div>
                            </div>
                            <h4 class="step-title">Confirm</h4>
                        </div>
                        <div class="step completed">
                            <div class="step-icon-wrap">
                                <div class="step-icon" id="success_id1"><i class="fas fa-check-circle"></i></div>
                            </div>
                            <h4 class="step-title">Success</h4>
                        </div>
                        <div class="step completed">
                            <div class="step-icon-wrap">
                                <div class="step-icon" id="overdue_id1"><i class="fas fa-exclamation-triangle"></i>
                                </div>
                            </div>
                            <h4 class="step-title">Overdue</h4>
                        </div>
                    </div>
                </div>
            </div>



            <div class="modal-content modalss" id="btntrack" *ngIf="inputId==2">
                <h4 style="padding: 3%; color: white;font-weight: bold;"><b>Your Progress Status is : </b></h4>
                <div class="container ">
                    <div
                        class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                        <div class="step completed">
                            <div class="step-icon-wrap">
                                <div class="step-icon" id="wait_id1"><i class="fas fa-clock"></i>
                                </div>
                            </div>
                            <h4 class="step-title">Wait</h4>
                        </div>
                        <!-- <div class="step completed">
                        <div class="step-icon-wrap">
                            <div class="step-icon" id="confirm_id1"><i class="fas fa-check-circle"></i></div>
                        </div>
                        <h4 class="step-title">Confirm</h4>
                    </div> -->
                        <div class="step completed">
                            <div class="step-icon-wrap">
                                <div class="step-icon" id="success_id1"><i class="fas fa-check-circle"></i></div>
                            </div>
                            <h4 class="step-title">Success</h4>
                        </div>
                        <div class="step completed">
                            <div class="step-icon-wrap">
                                <div class="step-icon" id="overdue_id1"><i class="fas fa-exclamation-triangle"></i>
                                </div>
                            </div>
                            <h4 class="step-title">Overdue</h4>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal-content modalss" id="btntrack" *ngIf="inputId==3">
                <h4 style="padding: 3%; color: white;font-weight: bold;"><b>Your Progress Status is : </b></h4>
                <div class="container ">
                    <div
                        class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                        <!-- <div class="step completed">
                            <div class="step-icon-wrap">
                                <div class="step-icon" id="wait_id1"><i class="fas fa-clock"></i>
                                </div>
                            </div>
                            <h4 class="step-title">Wait</h4>
                        </div> -->
                        <!-- <div class="step completed">
                        <div class="step-icon-wrap">
                            <div class="step-icon" id="confirm_id1"><i class="fas fa-check-circle"></i></div>
                        </div>
                        <h4 class="step-title">Confirm</h4>
                    </div> -->
                        <div class="step completed">
                            <div class="step-icon-wrap">
                                <div class="step-icon" id="success_id1"><i class="fas fa-check-circle"></i></div>
                            </div>
                            <h4 class="step-title">Success</h4>
                        </div>
                        <div class="step completed">
                            <div class="step-icon-wrap">
                                <div class="step-icon" id="overdue_id1"><i class="fas fa-exclamation-triangle"></i>
                                </div>
                            </div>
                            <h4 class="step-title">Overdue</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>