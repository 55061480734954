<!DOCTYPE html>

<html lang="en">
    <head>
        <!-- <title>Excoin cryptowatch</title> -->
    </head>
<body>
    <app-header></app-header>
    <div class="banner-block">

        <div class="container">
            <div class="row">
                <div class="col" id="content1">
                    <h2 style="color: white;float: left; padding-top: 2%;padding-bottom: 2%;"><strong>3. Transfer your
                            funds to the exchange service</strong></h2>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col">
                    <form>
                        
                        
                        <div class="form-row">
                            <div class="col-11 ">
                                <h5>Transaction Id:</h5>
                                <h4 style="font-size:x-large;"><strong>{{transc_id}}</strong> </h4>

                                <div class="form-row" id="form-row">
                                    <div class="col-5">
                                        <label for="exampleFormControlTextarea1" style="float: left;">You sent:</label>
                                    </div>
                                    <div class="col-5">
                                        <label for="exampleFormControlTextarea1" style="float: left;">You will get:</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-5 ">
                                        <h4 style="float: left;"><strong>{{amt_from}} {{coin_from}}</strong></h4>
                                    </div>
                                    <div class="col-5 ">
                                        <h4 style="float: left;"><strong>{{amt_to}} {{coin_to}}</strong></h4>
                                    </div>
                                </div>





                            </div>
                            
                            <div class="col-sm-1 col-xs-6 "  >
                                <div class="float-right qrcode">
                                    <ngx-qrcode qrc-element-type="url" [value]="cotoex"></ngx-qrcode>
                                </div>
                            </div>
                            
                        </div>
                        <div class="form-row">
                            <!-- <h4 style="font-size:x-large;"><strong>0.03 BTC</strong> </h4> -->
                        </div>
                       <!-- ----------------- -->
                        <!-- <div class="form-row">
                            <div class="col-6">
                                <h4 style="float: left;"><strong>0.01 BTC</strong></h4>
                            </div>
                            <div class="col-6">
                                <h4 style="float: left;"><strong>~1.74208637 ETH</strong></h4>
                            </div>
                        </div> -->
                        <div class="form-row" id="form-row">
                            <div class="col">
                                <label for="exampleFormControlTextarea1" style="float: left;">Exchange rate:</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <h4 style="float: left;"><strong>1 {{coin_from}} = {{exchangeRate}}
                                    {{coin_to}}</strong></h4>
                            </div>
                        </div>
                        <div class="form-row" id="form-row">
                            <div class="col">
                                <label for="exampleFormControlTextarea1" style="float: left;">Destination
                                    address:</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-7">

                                <h4  id="address" #userinput>
                                    {{deposit_addr}}
                                    <i class="fas fa-copy" (click)="copyMessage(deposit_addr)"
                                        value="click to copy" style="padding-left: 15px;"></i>
                                </h4>
                            </div>
                            <div class="col-md-3 m-2 timerow">
                                <div class="row ">
                                    <div class="col-4 ">
                                        <label >
                                            Deposit Time:
                                        </label>
                                    </div>
                                    <div class="col-8">
                                        <h4 style=" color: red; " id="demo">
                                            
                                            <!-- {{daysToDday}}:{{hoursToDday}}:{{minutesToDday}}:{{secondsToDday}} -->
                                        </h4>
                                        <p></p>                          
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="form-row" style="padding-top: 5%;margin-left: 20%;">
                        <div class="container pt-8">
                            <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x col-8">
                                <div class="step completed">
                                    <div class="step-icon-wrap" >
                                        <div class="step-icon" id="wait_id"> <i class="fas fa-clock"></i>
                                        </div>
                                    </div>
                                    <h4 class="step-title">Wait</h4>
                                </div>
                                <div class="step completed">
                                    <div class="step-icon-wrap">
                                        <div class="step-icon"  id="confirm_id"><i class="fas fa-check-circle"></i></div>
                                    </div>
                                    <h4 class="step-title">Confirm</h4>
                                </div>
                                <div class="step completed">
                                    <div class="step-icon-wrap">
                                        <div class="step-icon" id="success_id"><i class="fas fa-check-circle"></i></div>
                                    </div>
                                    <h4 class="step-title">Success</h4>
                                </div>
                                <div class="step completed">
                                    <div class="step-icon-wrap">
                                        <div class="step-icon" id="overdue_id"><i class="fas fa-exclamation-triangle"></i></div>
                                    </div>
                                    <h4 class="step-title">Overdue</h4>
                                </div>
                            </div>
                        </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col" id="content1">
                    <h4 style="padding-top: 2%;float:left"><strong>4. Wait for the exchange to proceed</strong></h4>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

        <script src="./assets/js/jquery-1.12.4.min.js"></script>
        <script src="./assets/js/popper.min.js"></script>
        <script src="./assets/js/bootstrap.min.js"></script>
        <script src="./assets/js/slick.min.js"></script>
        <script src="./assets/js/jquery.peity.min.js"></script>
        <script src="./assets/js/jquery.slimscroll.min.js"></script>
        <script src="./js/custom.js"></script>
</body>

</html>