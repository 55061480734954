import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import Swal from 'sweetalert2'
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.css']
})
export class Step3Component implements OnInit, OnDestroy {

  countDown: Subscription;

  private subscription!: Subscription;

  counter = 7200;
  tick = 1000;
  transArr: { destination_addr: any; deposit_addr: any; execution_time: any; };
  user_id: string;
  timeDifference: any;
  exchangerName: any;
  cotoex: any;
  DestinationAddress: any;
  transc_id: any;
  responseObjExolix: any;
  coin_from: any;
  coin_to: any;
  amt_from: any;
  amt_to: any;
  deposit_addr: any;
  exchangeRate: any;
  status: any;

  display: any;

  waitcss: any;
  confirmcss: any;
  successcss: any;
  overduecss: any;

  hours: any;
  minutes: any;
  seconds: any;

  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;


  secondsToDday: any;
  minutesToDday: any;
  hoursToDday: any;
  daysToDday: any;

  d: any;
  h: any;
  m: any;
  s: any;

  x: any;

  createdAt: any;
  expiresAt: any;

  timecheck: any;

  state:any;

  constructor(location: LocationStrategy, private route: Router, private activatedRoute: ActivatedRoute, private mysql: MysqlserviceService) {
    window.scroll(0, 0);
    this.state = this.route.getCurrentNavigation().extras.state
    this.transc_id = this.state.dest_addr
    setInterval(() =>
      this.transactionStatus()
      , 2000);

    location.onPopState(() => {
      this.route.navigate(['/dashboard'], { replaceUrl: true });
    });
    const token = localStorage.getItem("SeesionUser")
    this.user_id = token;
    // this.activatedRoute.params.subscribe(data => {
    //   this.transc_id = String(data.dest_addr)
    //   // this.exchangerName = String(data.exchangerName)
    // });
  }

  ngOnInit() {
    this.createdAt = 0
    this.expiresAt = 0

    this.waitcss = document.getElementById('wait_id');
    this.confirmcss = document.getElementById('confirm_id');
    this.successcss = document.getElementById('success_id');
    this.overduecss = document.getElementById('overdue_id');

    var data = { trans_id: this.transc_id }
    this.mysql.getExchngeInfoSwapspace(data).subscribe((exchange: any) => {
      // console.log('\n\n code' + JSON.stringify(exchange))
      this.DestinationAddress = exchange.from.address;
      this.coin_from = (exchange.from.code).toUpperCase()
      this.coin_to = (exchange.to.code).toUpperCase()
      this.deposit_addr = exchange.to.address
      this.amt_from = exchange.from.amount
      this.amt_to = exchange.to.amount
      this.exchangeRate = exchange.rate
      this.cotoex = this.deposit_addr
      this.status = exchange.status

      this.createdAt = exchange.timestamps.createdAt
      this.expiresAt = exchange.timestamps.expiresAt

      this.timer1(this.expiresAt);

    }, (e) => {
      // console.log("errorrrrrrrrrrr jsbdbds +++++++++++" + JSON.stringify(e))
      Swal.fire({
        title: 'Error',
        text: `Something went wrong, try again later`,
        icon: 'error',
        confirmButtonText: 'OK',
        width: '400'
      })
    })
  }

  ngAfterViewInit() {

  }

  timer1(ee) {
    // console.log('eee'+ee)

    if (ee == ' ' || ee == null || ee == undefined || ee == 0) {
      if (document.getElementById("demo") != null) {
      document.getElementById("demo").innerHTML = 'not available'
    }
    }

    else {
      var c = this.createdAt + "Z"
      var e = ee + "Z"
      var dE: any = new Date(e).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
      // var dC = new Date(c).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
      this.timecheck = dE
      var countDownDate = new Date(`${dE}`).getTime();
      this.x = setInterval(function () {
        var now = new Date().getTime();
        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        // Time calculations for days, hours, minutes and seconds
        this.d = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.s = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        if (document.getElementById("demo") != null) {
          document.getElementById("demo").innerHTML = this.d + "d " + this.h + "h "
            + this.m + "m " + this.s + "s ";
        }

        // If the count down is over, write some text 
        var bbb = new Date().toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
        // console.log('timechecking dE : '+new Date(dE).getTime()+'\nbbb : '+new Date(bbb).getTime()+'\ndate.now()'+Date.now())
        if (new Date(dE).getTime() < new Date(bbb).getTime()) {
          clearInterval(this.x);
          if (document.getElementById("demo") != null) {
          document.getElementById("demo").innerHTML = "EXPIRED";
        }
          this.status
        }
        // if (dE < 0) {
        //   clearInterval(this.x);
        //   document.getElementById("demo").innerHTML = "EXPIRED";
        // }
      });
    }
    // this.countDown = timer(0, this.tick).subscribe(() => --this.counter);
  }


  transactionStatus() {

    var data = { trans_id: this.transc_id }
    this.mysql.getExchngeInfoSwapspace(data).subscribe((exchange: any) => {
      // console.log("getExchngeInfoSwapspace"+JSON.stringify(exchange))
      // console.log('exchange status'+exchange.status)
      this.status = exchange.status

      if (this.status == 'waiting') {
        this.waitcss.style.background = 'lawngreen';
        var bbb = new Date().toLocaleString(undefined, { timeZone: 'Asia/Kolkata' });
        if (new Date(this.timecheck).getTime() < new Date(bbb).getTime()) {
          this.waitcss.style.background = 'red';
          this.confirmcss.style.background = 'red';
          this.successcss.style.background = 'red';
          this.overduecss.style.background = 'red';
        }
      }
      if (this.status == 'confirming') {
        this.waitcss.style.background = 'lawngreen';
        this.confirmcss.style.background = 'lawngreen';
      }
      if (this.status == 'finished') {
        this.waitcss.style.background = 'lawngreen';
        this.confirmcss.style.background = 'lawngreen';
        this.successcss.style.background = 'lawngreen';
      }
      if (this.status == 'failed') {
        this.waitcss.style.background = 'lawngreen';
        this.confirmcss.style.background = 'lawngreen';
        this.successcss.style.background = 'lawngreen';
        this.overduecss.style.background = 'red';
      }
    }, (e) => {
      // console.log("getExchngeInfoSwapspace error"+JSON.stringify(e))
      Swal.fire({
        title: 'Error',
        text: `Something went wrong, try again later`,
        icon: 'error',
        confirmButtonText: 'OK',
        width: '400'
      })
    })
  }

  ngOnDestroy() {
    clearInterval(this.x)
    // console.log('destroy called')
    this.createdAt = 0
    this.expiresAt = 0
    this.counter = null
    this.d = 0
    this.h = 0;
    this.m = 0
    this.s = 0
    document.getElementById("demo") == null
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    // alert('Copied')
  }
}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60);
    return (
      ("00" + hours).slice(-2) +
      ":" +
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

}
