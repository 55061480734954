




<!-- <script type="text/javascript">
    function sendEmail() {

        alert("mail sent successfully")

    }
</script>
    <form method="post">
        <input type="button" value="Send Email" onclick="sendEmail()" />
    </form> -->




    <router-outlet></router-outlet>
    