import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment'


const swapspaceAPI = environment.swapspace.baseapi;
const swapspaceAPIkey = environment.swapspace.APIkey;

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})

export class Step2Component implements OnInit {
  transaction_data = [];
  table_id: any;
  drp_1: any;
  drp_2: any;
  amount: any;
  exolicDefault: any;
  convertedAmt: any;
  generated_id: any;
  @ViewChild('dest_addr') dest_addrElement: ElementRef;
  @ViewChild('refund_addr') refund_addrElement: ElementRef;
  ChangeNowValue: any;
  address: any;
  check: any;
  user_id: any;
  transArr: any;
  toLower1: string;
  toLower2: string;
  ipAddress: any;
  loader: any = 1;
  state:any;
  constructor(private formm: FormBuilder, private http: HttpClient, private route: Router, private mysql: MysqlserviceService) {
    window.scroll(0, 0);
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        // console.log('th data', data);
        this.ipAddress = data.ip
        // console.log("sandesh ip"+ this.ipAddress)
      })
    const token = localStorage.getItem("SeesionUser")
    // console.log("token" + token)
    this.user_id = token;
    // this.activatedRoute.params.subscribe(data => {
    //   this.table_id = String(data.table_id)
    //   this.drp_1 = String(data.drp_1)
    //   this.drp_2 = String(data.drp_2)
    //   this.amount = String(data.amt)
    // });
    this.state = this.route.getCurrentNavigation().extras.state
    this.table_id =this.state.table_id
    this.drp_1 = this.state.drp_1
    this.drp_2 = this.state.drp_2
    this.amount = this.state.amt
  }

  recipient = this.formm.group({
    address: ['', Validators.required],
    check: ['', Validators.required]
  });

  public errMsg = {
    address: [
      { type: 'required', message: 'Please enter correct address' },
    ],
    check: [
      { type: 'required', message: 'Please accept the Terms of Policy' },
    ],
  }

  ngOnInit(): void {
    // const token = this.storage.get("SeesionUser")
    this.toLower1 = String(this.drp_1).toLowerCase()
    this.toLower2 = String(this.drp_2).toLowerCase()

    this.http.get(`${swapspaceAPI}/amounts?amount=${this.amount}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=${this.table_id}&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((fox: any) => {
      // console.log("jsbdbds +++++++++++" + JSON.stringify(fox))
      this.convertedAmt = fox[0].toAmount
    }, (e => {
      Swal.fire({
        title: 'Error',
        text: `Try another Exchanger \n Something went wrong`,
        icon: 'error',
        confirmButtonText: 'Ok',
        width: '400'
      })

    }))
    // console.log("table id=" + this.table_id + "\ndrp_1=" + this.drp_1 + "\ndrp_2=" + this.drp_2 + "\namt=" + this.amount)
    // console.log("destination address" + this.dest_addrElement)
  }

  gotoStep3() {
    this.clickedExchanger();
    this.loader = 0;

  }

  clickedExchanger() {
    this.toLower1 = String(this.drp_1).toLowerCase()
    this.toLower2 = String(this.drp_2).toLowerCase()

    this.mysql.getTxIdSwapSpace(this.table_id, this.toLower1, this.toLower2, this.dest_addrElement.nativeElement.value, this.amount, this.ipAddress).
      subscribe((a: any) => {

        this.generated_id = a.id
        this.transaction_data.push({
          user_id: this.user_id,
          id: a.id,
          to_coin: a.to.code,
          from_coin: a.from.code,
          to_amount: a.to.amount,
          from_amount: a.from.amount,
          transaction_date: a.timestamps.createdAt,
          transaction_status: a.status
        })

        this.mysql.postTransactionData(this.transaction_data).subscribe((res: any) => {
        })
      }, (e) => {
        // console.log("error post exchange"+JSON.stringify(e))
        Swal.fire({
          title: 'Error',
          text: `Try another Exchanger ,Something went wrong `,
          icon: 'error',
          confirmButtonText: 'OK',
          width: '400'
        }).then(a => {
          // this.route.navigateByUrl(`/view-table`)
          this.loader = 1;
          this.recipient.reset();
        })
      }, () => {
        this.mysql.getUserMail(this.user_id).subscribe(a => {
          this.mysql.sendMailTransac(a[0].email, this.generated_id, a[0].f_name).subscribe(a => {
            // this.route.navigateByUrl(`/step3/${this.generated_id}`)
            this.route.navigateByUrl('/step3',{ state: { dest_addr: this.generated_id } });

          }, (e) => {
            this.loader = 0;
          }, () => {
          })
        },(e)=>{
          this.loader = 0;
        })
      })
  }
}