import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2'
import { AppToastServiceService } from '../services/app-toast-service.service';

@Component({
  selector: 'app-signup2',
  templateUrl: './signup2.component.html',
  styleUrls: ['./signup2.component.css']
})
export class Signup2Component implements OnInit {
  arr: any = [];
  f_name: any;
  email: any;
  password: any;
  user_id: any;
  pan_no: any;
  loader: any = 1;

  constructor(public toastService: AppToastServiceService, private fb: FormBuilder, private mysqlService: MysqlserviceService, private activatedRoute: ActivatedRoute,
    private route: Router) {
    this.activatedRoute.params.subscribe(data => {
      // console.log('dest addr' + JSON.stringify(data))
      this.pan_no = data.pan
      // this.exchangerName = String(data.exchangerName)
    });
  }


  ngOnInit(): void {
    this.user_id = localStorage.getItem('userid')
    // console.log('user id in signup2' + this.user_id)
    this.mysqlService.getUsers2(this.user_id).subscribe((a: any) => {
      // console.log("UID & a-----------" + this.user_id + JSON.stringify(a));
      this.f_name = a[0].f_name;
      this.email = a[0].email;
      this.password = a[0].password;
      this.arr.push(a);
      // console.log("arrrr////////////" + this.f_name);
    })
  }
  regi = this.fb.group({
    f_name: ['', [Validators.required]]
  });

  public errMsg = {
    f_name: [
      { type: 'required', message: 'This field can not be blank' },
    ]
  }

  update() {
    this.loader = 0;
    var data = {
      pno: this.pan_no,
      email: this.email,
      pancard_status: 1
    }
    this.mysqlService.reqPAN(data).subscribe((a: any) => {
      // var abc = a.valueOf;
      // console.log("PAN response" + JSON.stringify(a))
      // console.log("name",a.result.user_full_name)
      // console.log("form name", this.regi.value.f_name)

      // console.log("pan response message~~~~~~~ ", a.response_message)

      //checks pan is valid or not
      if (a.response_message == "Valid Authentication") {
        // alert("valid authentication")
        // if PAN valid checks username with PAN usename
        var panResponseName = a.result.user_full_name
        var form_name = this.regi.value.f_name
        // console.log("Userid check..." + this.user_id);
        // this.mysqlService.getUsers2(this.user_id).subscribe(e => {
        // console.log("User detailss..." + this.regi.value.f_name)
        console.log(panResponseName.toLowerCase())
        if (panResponseName.toLowerCase() != this.regi.value.f_name.toLowerCase()) {
          Swal.fire({
            title: 'Failure',
            text: 'Enter full name as per your pan',
            icon: 'error',
            confirmButtonText: 'cancel',
            width: '400'
          })
          this.loader = 1
        }

        else {

          //----------add pan number to kyc master----------------
          let data2 = [{ pancard_no: this.pan_no, user_id: this.user_id }]
          this.mysqlService.addpannumber(data2).subscribe((a: any) => {
            // console.log("Add pan no response" + JSON.stringify(a));
          }, (e) => {
            Swal.fire({
              title: 'Failure',
              text: 'Please enter pan number ',
              icon: 'error',
              confirmButtonText: 'cancel',
              width: '400'
            })
            this.loader = 1
          },
            () => {
              var data = {
                user_id: this.user_id,
                f_name: this.regi.value.f_name
              }

              this.mysqlService.updateUserData(data).subscribe((a: any) => {
                // console.log('Response generated in ts file' + JSON.stringify(a));
              }, (e) => {
                this.loader = 1
              }, () => {
                var data3 = {
                  email: this.email,
                  pancard_status: 1
                }

                this.mysqlService.updatepanstatus(data3).subscribe((a: any) => {
                  // console.log("ressss" + JSON.stringify(a));
                }, (e) => {

                }, () => {
                  this.toastService.showSuccess('KYC verification done successfully')
                  this.route.navigateByUrl('/login')
                })
              })
            })
        }
      }

      else {
        this.loader = 1;
        Swal.fire({
          title: 'Failure',
          text: 'Invalid PAN',
          icon: 'error',
          confirmButtonText: 'cancel',
          width: '400'
        })
      }
    },
      (e) => {
        this.loader = 1;
      }, () => {

      })
  }
}
