import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'

const baseUrl = environment.index.baseapi;
// const baseUrl='https://cotoexapi.cotoex.com';

@Injectable({
  providedIn: 'root'
})
export class MysqlserviceService {

  constructor(private httpClient: HttpClient) { }

  postTransactionData(data) {
    return this.httpClient.post(`${baseUrl}/transaction_details`, data)
  }
  uploadImg(user_id, formData) {
    return this.httpClient.post(`${baseUrl}/upload/${user_id}`, formData)
  }
  uploadPanImg(user_id, formData) {
    return this.httpClient.post(`${baseUrl}/uploadpan/${user_id}`, formData)
  }

  addUser(data) {
    // console.log("in service" + JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/add_user`, data);
  }
  getUsername(user_id) {
    return this.httpClient.get(`${baseUrl}/get_user_name/${user_id}`)
  }

  getUsers() {
    return this.httpClient.get(`${baseUrl}/get_user_master`)
  }

  getUsers2(user_id) {
    return this.httpClient.get(`${baseUrl}/get_user_master2/${user_id}`)
  }

  getTxIdSwapSpace(partner, from, to, address, amount, ipAddress) {
    // console.log("in mysql" + partner, from, to, address, amount)
    return this.httpClient.post(`${baseUrl}/post_swapspace_id`, { partner, from, to, address, amount, ipAddress });
  }

  getExchngeInfoSwapspace(data) {
    // console.log("in mysql" + JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/getTransactionInfoSwapspace`, data);
  }

  sendForgotMail(email2,user_otp) {
    console.log('in get mail2 fun' + email2)
    return this.httpClient.get(`${baseUrl}/get_forgot_mail/${email2}/${user_otp}`)
  }

  sendMail(fname, email) {
    // console.log('in get mail mysql' + fname)
    return this.httpClient.get(`${baseUrl}/get_mail/${fname}/${email}`)
  }

  sendMailTransac(email, Tid, fname) {
    // console.log('in send trans mail mysql ' + email)
    return this.httpClient.get(`${baseUrl}/send_mail/${email}/${Tid}/${fname}`)
  }

  getUserId(email) {
    // console.log("in service" + email)
    return this.httpClient.post(`${baseUrl}/get_userid`, email)
  }

  getUserMail(user_id) {
    return this.httpClient.get(`${baseUrl}/get_usermail/${user_id}`)
  }

  addaadharnumber(data) {
    // console.log("in service dhr numbr" + JSON.stringify(data));
    return this.httpClient.post(`${baseUrl}/addaadhar`, data);
  }

  updatepanstatus(data) {
    // console.log("in service pan numbr" + JSON.stringify(data));
    return this.httpClient.post(`${baseUrl}/updatepancard`, data);
  }

  addpannumber(datas) {
    // console.log("in service" + JSON.stringify(datas));
    return this.httpClient.put(`${baseUrl}/addpan`, datas);
  }
  updatePassword(data){
    return this.httpClient.post(`${baseUrl}/updatepassword`,data);
  }
  //--------------------------add KYC details to mysql----------------------------------------

  addKycDetails(data) {
    // console.log("data of add kyc details in service")
    return this.httpClient.post(`${baseUrl}/add_kyc_details`, data);
  }

  // ---------------------------------------------- Adhar Pan KYC Verification -----------------------------------

  reqAdhar(datas) {
    return this.httpClient.post(`${baseUrl}/request_verify_adhar`, datas);
  }

  resAdhar(datas) {
    return this.httpClient.post(`${baseUrl}/response_verify_adhar`, datas);
  }

  reqPAN(datas) {
    // console.log("request pan in service", datas)
    return this.httpClient.post(`${baseUrl}/request_verify_pan`, datas);
  }

  updateUserData(data) {
    // console.log("update data in service file" + JSON.stringify(data))
    return this.httpClient.put(`${baseUrl}/update_user_name`, data);
  }

  get_encrypted_password(email) {
    // console.log("in service get encypted password email : ", email)
    return this.httpClient.get(`${baseUrl}/get_encrypted_password/${email}`);
  }


  //------------------------------for validating user email by sending otp----------------------------
  validateEmail(user_email, user_otp) {
    // console.log("in serviceeeee", user_email, user_otp)
    return this.httpClient.get(`${baseUrl}/validate_user_mail/${user_email}/${user_otp}`)
  }

  // ---------------------------------- adding bigb transaction data ---------------------------------

  addBigbTransactionDetails(data) {
    // console.log("in service transactions" + JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/bigb_transaction_details`, data);
  }

  addBigbInrTransactionDetails(data) {
    // console.log("in service transactions" + JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/bigb_inr_transaction_details`, data);
  }

  updateBigbInrTransactionData(data) {
    // console.log("update data in service file" + JSON.stringify(data))
    return this.httpClient.put(`${baseUrl}/update_bigb_inr_transaction_details`, data);
  }
  
  // ------------------------------------ update transaction status in Transaction Table -----------------------

  updateSSTransactionData(data) {
    // console.log("update data in service file" + JSON.stringify(data))
    return this.httpClient.put(`${baseUrl}/update_transaction_details`, data);
  }

  // ------------------------------------ update transaction status in bigb_transaction Table -----------------------
  updateBBTETransactionData(data) {
    // console.log("update data in service file" + JSON.stringify(data))
    return this.httpClient.put(`${baseUrl}/update_bbtetransaction_details`, data);
  }

  updateBigbInrBigbStatus(data) {   //update bigbstatus in bigb_inr_transaction table
    // console.log("update data in service file" + JSON.stringify(data))
    return this.httpClient.put(`${baseUrl}/updateInrBigbStatus`, data);
  }

  updateBiggEBBTBigbStatus(data) {    //update bigbstatus in bigb_transaction table
    // console.log("update data in service file" + JSON.stringify(data))
    return this.httpClient.put(`${baseUrl}/updateETBBBigbStatus`, data);
  }

  getbigbInrStatus(order_id) {
    // console.log("in serviceeeee", order_id)
    var data = { order_id:order_id }
    return this.httpClient.post(`${baseUrl}/get_inr_bigb_status`,data)
  }

  getBigbTransactionStatus(data){  //sandesh
    return this.httpClient.post(`${baseUrl}/bigb_transaction_status`, data);
  }

  sendtoken(data) {  //used in bigb-step2 (Ippopay) & bigb-step3 (Coinpayment)
    // console.log("in service pan numbr" + JSON.stringify(data));
    return this.httpClient.post(`${baseUrl}/sendtoken`, data);
  }

  getBigbTransactionStatusTrack(data){
    return this.httpClient.post(`${baseUrl}/bigbStatus_Transaction`, data);
  }

  getMinRateBigb(){
    return this.httpClient.get(`${baseUrl}/getMinBigbPrice`);
  }


  // --------------------------------------- coinpayments calling -------------------------------------- 

  minmaxCoinpayment(currency) {
    var data = {
      from:currency
    }
    // console.log('minmaxCoinpayment in mysql service'+JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/coinpayments_minmax`, data)
  } //not called

  createTransCoinpayment(data) {
    // console.log('createTransCoinpayment in mysql service'+JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/coinpayments_createtransaction`, data)
  }

  transactionCoinpayment(data) {
    // console.log('transaction coinpayment in mysql service'+JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/coinpayments_gettransactioninfo`, data)
  }

  // ----------------------------- Razorpay callings ------------------------------------ 
  createorderId(data) {
    return this.httpClient.post(`${baseUrl}/createorderId`, data);
  }
  paymentVerify(data) {
    // console.log("in service" + JSON.stringify(data))
    return this.httpClient.post(`${baseUrl}/paymentverify`, data);
  }
// ---------------------------------------- Get Bigb Current Price ------------------------------------

getBigbPrice() {
  // console.log("update data in service file" + JSON.stringify(data))
  return this.httpClient.get(`${baseUrl}/getBigbPrice`);
}

// ----------------------------- get current USD price ---------------------------------------------
getUSDPrice() {
  // console.log("update data in service file" + JSON.stringify(data))
  return this.httpClient.get(`${baseUrl}/usdRate`);
}

//  ----------------------------------------- IPOPAY Gateway ----------------------------------------
ipopayCallFun(data) {
  console.log("ipopayCallFun data in service file" + JSON.stringify(data))
  return this.httpClient.post(`${baseUrl}/ipopaynode`,data);
}

  // ------------------------------------Admin Database------------------------

  adminlogin(data:{}){
  return this.httpClient.post(`${baseUrl}/loginadmin`,data);
}


getSwapTransaction() {
  // console.log("in service transactions" + JSON.stringify(data))
  return this.httpClient.get(`${baseUrl}/bigb_inr_transaction`);
}

searchuserid(list) {
  console.log("data in ts" + JSON.stringify(list.transid))
  return this.httpClient.get(`${baseUrl}/searchadmin/${list.transid}`);
}

searchdate(list){
  console.log("searchdate",list.start)
  return this.httpClient.get(`${baseUrl}/searchdate/${list.start}/${list.end}`);
}



  // recognizeImagePan(user_id){
  //   return this.httpClient.get(`${baseUrl}/recognize_img_pan/${user_id}`);
  // }

  // recognizeImageAdhar(user_id){
  //   return this.httpClient.get(`${baseUrl}/recognize_img_adhar/${user_id}`);
  // }

  // deleteImageAdhar(user_id){
  //   return this.httpClient.get(`${baseUrl}/unlink_adhar/${user_id}`);
  // }

  // deleteImagePan(user_id){
  //   return this.httpClient.get(`${baseUrl}/unlink_pan/${user_id}`);
  // }
}