import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Pipe, PipeTransform, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import Swal from 'sweetalert2'
import { LocationStrategy } from '@angular/common';
import { environment } from '../../environments/environment'

const binanceAPI = environment.binance.baseapi;

@Component({
  selector: 'app-bigb-exchange-step3',
  templateUrl: './bigb-exchange-step3.component.html',
  styleUrls: ['./bigb-exchange-step3.component.css']
})
export class BigbExchangeStep3Component implements OnInit, AfterViewInit {

  countDown: any;
  counter = 0;
  tick = 1000;
  transArr: { destination_addr: any; deposit_addr: any; execution_time: any; };
  user_id: string;
  timeDifference: any;

  exchangerName: any;
  cotoex: any;
  DestinationAddress: any;
  transc_id: any;
  coin_from: any;
  coin_to: any;
  amt_from: any;
  amt_to: any;
  deposit_addr: any;
  exchangeRate: any;
  status: any;

  waitcss: any;
  successcss: any;
  overduecss: any;

  hours: any;
  minutes: any;
  seconds: any;

  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  secondsToDday: any;
  minutesToDday: any;
  hoursToDday: any;
  daysToDday: any;

  d: any;
  h: any;
  m: any;
  s: any;

  x: any;

  createdAt: any;
  expiresAt: any;

  user_addr: any;
  amount_From: any;
  amount_To: any;


  showmsg = 0;
  currencyPrice: any;
  BIGBPrice$: any;

  CPSendInrForToken:any;

  state:any;

  constructor(location: LocationStrategy, private route: Router, private activatedRoute: ActivatedRoute, private http: HttpClient, private mysql: MysqlserviceService) {
    window.scroll(0, 0);
    this.state = this.route.getCurrentNavigation().extras.state
    this.transc_id = this.state.dest_addr
      this.amount_From = this.state.amount;
      this.amount_To = this.state.convertedAmt;
      this.user_addr = this.state.user_addr
    this.x = setInterval(() =>
      this.transactionStatus()
      , 10000);

    location.onPopState(() => {
      this.route.navigate(['/dashboard'], { replaceUrl: true });
    });
    const token = localStorage.getItem("SeesionUser")
    // console.log("token" + token)
    this.user_id = token;
    // this.activatedRoute.params.subscribe(data => {
    //   // console.log('dest addr' + JSON.stringify(data))
    //   this.transc_id = String(data.dest_addr)
    //   this.amount_From = data.amount;
    //   this.amount_To = data.convertedAmt;
    //   this.user_addr = data.user_addr
    //   // this.exchangerName = String(data.exchangerName)
    // });
  }

  ngOnInit() {
    this.createdAt = 0
    this.expiresAt = 0

    this.waitcss = document.getElementById('wait_id');
    this.successcss = document.getElementById('success_id');
    this.overduecss = document.getElementById('overdue_id');

    // this.timer1()

    console.log('dest addr' + this.transc_id);
    var dataArr = { trans_id: this.transc_id }

    this.mysql.transactionCoinpayment(dataArr).subscribe((a: any) => {
      console.log('response transactionCoinpayment' + JSON.stringify(a))

      this.DestinationAddress = 'a.from.address'; //take this from step 2
      this.coin_from = String(a.coin).toUpperCase()
      this.coin_to = 'BIGB'
      this.deposit_addr = a.payment_address
      this.amt_from = a.amountf
      this.amt_to = this.amount_To // need to calculate
      this.exchangeRate = ''  //set manually
      this.cotoex = this.deposit_addr
      this.status = a.status
      this.createdAt = a.time_created
      this.expiresAt = a.time_expires

      var diff = this.expiresAt - this.createdAt
      var ad = Date.now();
      var abd = new Date(ad).getTime()

      console.log('createdAt' + abd + '\nexpiresAt' + diff)
      // setInterval(async () => {
      // this.transactionStatus();
      this.timer1(diff);
      // }, 1000);
    }, (e) => {
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong, Try again later',
        icon: 'error',
        confirmButtonText: 'Cancel',
        width: '400'
      })
    }, () => {
      
      this.http.get(`${binanceAPI}/avgPrice?symbol=${this.coin_from}USDT`).subscribe(b1 => {
        this.currencyPrice = b1.valueOf()
      }, (e) => {
        Swal.fire({
          title: 'Error',
          text: 'Something went wrong, Try again later',
          icon: 'error',
          confirmButtonText: 'Cancel',
          width: '400'
        })
      }, () => {
          this.mysql.getBigbPrice().subscribe((b: any) => {
            this.BIGBPrice$ = b.dollarPrice
            this.CPSendInrForToken = this.amt_to * b.INRPrice
            console.log('calculated CPSendInrForToken',this.CPSendInrForToken)
          }, (e) => {
            Swal.fire({
              title: 'Error',
              text: 'Something went wrong, Try again later',
              icon: 'error',
              confirmButtonText: 'Cancel',
              width: '400'
            })
           }, () => {
            
            this.currencyPrice = this.currencyPrice.price 
            this.exchangeRate = this.currencyPrice / this.BIGBPrice$
            
            console.log("BIGBPRICE in INR : ", this.BIGBPrice$, "  converted amt  : ",this.exchangeRate ,' fromcurrency price : ',this.currencyPrice )
          })
      })
    })
  }

  ngAfterViewInit() {
  }

  timer1(ee) {
    var miliT = ee * 1000;

    if (ee == ' ' || ee == null || ee == undefined || ee == 0) {
      // console.log('in else')
      if (document.getElementById("demo") != null){
      document.getElementById("demo").innerHTML = 'not available'
    }
    }
    else {

      this.counter = ee;
      this.countDown = timer(0, this.tick).subscribe((a) => {
        --this.counter
        if (this.counter == 0) {
          this.showmsg = 1;
        }
      }, (e) => {
        this.showmsg = 0;
      }, () => {
      });
    }
  }


  transactionStatus() {
    // this.timer1();
    // console.log("sandesh");

    var dataArr2 = { trans_id: this.transc_id }

    this.mysql.transactionCoinpayment(dataArr2).subscribe((exchange: any) => {
      this.status = exchange.status
      // console.log('status'+this.status)
      if (this.status == 0) {
        this.waitcss.style.background = 'lawngreen';
      }
      if (this.status == 1) {
        console.log('calculated CPSendInrForToken',this.CPSendInrForToken)
        this.waitcss.style.background = 'lawngreen';
        this.successcss.style.background = 'lawngreen';
        var transData = {
          trans_id : this.transc_id
        }
        this.mysql.getBigbTransactionStatus(transData).subscribe((bStatus:any) => {
          if(bStatus[0].bigb_status == ' ' ){
            var data = {
              amountInr: this.CPSendInrForToken, //how much bigb tokens need to send
              usertrustwalletaddress : this.user_addr
            }
            this.mysql.sendtoken(data).subscribe((restoken:any) => {
              if(restoken.status == 'success'){
                var data2 = {
                  trans_id: this.transc_id,
                  BigbStatus:'success',
                  transaction_status:'success'
                }
                this.mysql.updateBiggEBBTBigbStatus(data2).subscribe(a => {
                  console.log('status updated')
                  this.route.navigateByUrl('/dashboard')
                },(e)=>{
                  Swal.fire({
                    title: 'Error',
                    text: 'Something went wrong, Try again later',
                    icon: 'error',
                    confirmButtonText: 'Cancel',
                    width: '400'
                  })
                  // this.route.navigateByUrl('/dashboard')
                },() => {
                })
              }
              if(restoken.status == 'failed'){
                Swal.fire({
                  title: 'Error',
                  text: 'Something went wrong, Try again later',
                  icon: 'error',
                  confirmButtonText: 'Cancel',
                  width: '400'
                })
                this.route.navigateByUrl('/dashboard')
                // var data3 = {
                //   trans_id: this.transc_id,
                //   BigbStatus:'failed',
                //   transaction_status:'failed'
                // }
                // this.mysql.updateBiggEBBTBigbStatus(data3).subscribe(a => {
                //   console.log('status updated')
                //   // this.route.navigateByUrl('/dashboard')
                // },(e)=>{},() => {
                // })
              }
            },(e) =>{
              Swal.fire({
                title: 'Error',
                text: 'Something went wrong, Try again later',
                icon: 'error',
                confirmButtonText: 'Cancel',
                width: '400'
              })
              this.route.navigateByUrl('/dashboard')
            })
          }else{

          }
      },(e) => {
        Swal.fire({
          title: 'Error',
          text: 'Something went wrong, Try again later',
          icon: 'error',
          confirmButtonText: 'Cancel',
          width: '400'
        })
      })
        //send bigb token (done)
        //on succes or failure navigate to dashboard (done)
        //update in table (done)
      }
      if (this.status == -1) {
        this.waitcss.style.background = 'lawngreen';
        this.successcss.style.background = 'lawngreen';
        this.overduecss.style.background = 'red';
        var data3 = {
          trans_id: this.transc_id,
          BigbStatus:'failed',
          transaction_status:'failed'
        }
        this.mysql.updateBiggEBBTBigbStatus(data3).subscribe(a => {
          console.log('status updated')
          // this.route.navigateByUrl('/dashboard')
        },(e)=>{
          Swal.fire({
            title: 'Error',
            text: 'Something went wrong, Try again later',
            icon: 'error',
            confirmButtonText: 'Cancel',
            width: '400'
          })
        },() => {
        })
        //navigate to dashboard(done)
        //update in table(done)
      }
    }, (e) => {
      Swal.fire({
        title: 'Error',
        text: `Something went wrong, try again later`,
        icon: 'error',
        confirmButtonText: 'OK',
        width: '400'
      })
    })
  }

  ngOnDestroy() {
    clearInterval(this.x);
    console.log('destroy called')
    this.createdAt = 0
    this.expiresAt = 0
    this.counter = null
    this.d = 0
    this.h = 0;
    this.m = 0
    this.s = 0
    document.getElementById("demo").innerHTML = null
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    // alert('Copied')
  }
}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60);
    return (
      ("00" + hours).slice(-2) +
      ":" +
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }


}
