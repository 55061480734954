import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthserviceService } from './services/authservice.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardGuard implements CanActivate {

  constructor(private Authguardservice: AuthserviceService, private router: Router) { }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
  canActivate(): boolean {
    if (!this.Authguardservice.gettoken()) {
      this.router.navigateByUrl("/login");
    }
          return this.Authguardservice.gettoken();
    // if (!this.Authguardservice.gettokenadmin()) {
    //   check = 1;
    //   this.router.navigateByUrl("/adminlogin");
    // }
    // if(check == 0){
    //   return this.Authguardservice.gettoken();
    // }
    // else if(check == 1){
    //   return this.Authguardservice.gettokenadmin();
    // }
    //  && this.Authguardservice.gettokenadmin();
  } 

  // canActivate1():boolean{
  //   if (!this.Authguardservice.gettokenadmin()) {  
  //     this.router.navigateByUrl("/adminlogin");  
  // }  
  // return this.Authguardservice.gettokenadmin();
  }
