import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AppToastServiceService } from '../services/app-toast-service.service';
import { environment } from '../../environments/environment'

const binanceAPI = environment.binance.baseapi;
const swapspaceAPI = environment.swapspace.baseapi;
const swapspaceAPIkey = environment.swapspace.APIkey;

@Component({
  selector: 'app-view-offer-table',
  templateUrl: './view-offer-table.component.html',
  styleUrls: ['./view-offer-table.component.css']
})
export class ViewOfferTableComponent implements OnInit, AfterViewInit {
  @ViewChild('valueId') usernameElement: ElementRef;

  firstselect: any;
  secondselect: any;

  defaultVal = 1;
  defaultVal2;

  allVal: any = []

  getCoinData_A: any = [];
  getCoinData_B: any = [];
  mlistB: any = [];
  coinNetwork_A: any = [];
  coinNetwork_B: any = [];

  binanceCalVal: any;
  ChangeNowValue: any;
  simpleSwapCal: any;
  exolicCalVal: any;
  stealthExValue: any;
  godexCalVal: any;
  changellyCalVal: any;
  switchainCalVal: any;
  letsexchangeCalVal: any;
  NexchangeCalVal: any;
  changeheroCalVal: any;
  foxexchangeCalVal: any;

  cotoexcalval = 0;
  binanceCalVal1 = 0;
  ChangeNowValue1 = 0;
  simpleSwapCal1 = 0;
  exolicCalVal1 = 0;
  stealthExValue1 = 0;
  godexCalVal1 = 0;
  changellyCalVal1 = 0;
  switchainCalVal1 = 0;
  letsexchangeCalVal1 = 0;
  NexchangeCalVal1 = 0;
  changeheroCalVal1 = 0;
  foxexchangeCalVal1 = 0;

  cotoexDiv = 0;

  toLower1: any;
  toLower2: any;

  CotoexEnDis: any = 1;
  BinanceEnDis: any = 1;
  DisabledExolix: any = 1;
  ChangeNowEnDis: any = 1;
  SimSwapEnDis: any = 1;
  StealthEXEnDis: any = 1;
  GodexEnDis: any = 1;
  ChangellyEnDis: any = 1;
  swithainEnDis: any = 1;
  LetsExchangeEnDis: any = 1;
  NExchngerEnDis: any = 1;
  changeheroEnDis: any = 1;
  foxexchangeEnDis: any = 1;

  cotoexCss: any;
  binanceCss: any;
  exolixCss: any;
  changeNowCss: any;
  simpleSwapCss: any;
  stealthEXCss: any;
  godexCss: any;
  changellyCss: any;
  switchainCss: any;
  letsExchangeCss: any;
  NexchangeCss: any;
  changeHeroCss: any;
  foxexchangeCss: any;

  closeResult: any = '';

  mlist: any = [];
  name: string;
  name2: string;

  currencyPrice: any;
  INRRate: any;
  BIGBPrice: number;

  inputCalVal: any;
  bigb_bnb: any;
  usdConversion: any;
  drp_1: any;
  drp_2: any;
  currencyPrice1: any;
  state: any;
  load: any = 1;

  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, private toastService: AppToastServiceService, private mysql: MysqlserviceService, private fb: FormBuilder, private route: Router, private modalService: NgbModal) {
    // this.activatedRoute.params.subscribe(data => {
    //   this.drp_1 = String(data.drp_1)
    //   this.drp_2 = String(data.drp_2)
    // });
    this.state = this.route.getCurrentNavigation().extras.state
    this.drp_1 = this.state.drp_1
    this.drp_2 = this.state.drp_2

    setInterval(() =>
      this.secondDropdwnVal()
      , 1000);
  }
  ngAfterViewInit() {
    this.cotoexCss = document.getElementById('cotoexDefault1_id');
    this.exolixCss = document.getElementById('exolicDefault1_id');
    this.changeNowCss = document.getElementById('changenowDefault1_id');
    this.simpleSwapCss = document.getElementById('simpleSwapDefault1_id');
    this.stealthEXCss = document.getElementById('stealthEXDefault1_id');
    this.godexCss = document.getElementById('godexDefault1_id');
    this.changellyCss = document.getElementById('changellyDefault1_id');
    this.switchainCss = document.getElementById('switchainDefault1_id');
    this.letsExchangeCss = document.getElementById('letsExchangeDefault1_id');
    this.NexchangeCss = document.getElementById('NExchangeDefault1_id');
    this.binanceCss = document.getElementById('binanceDefault1_id');
    this.changeHeroCss = document.getElementById('changeheroDefault1_id');
    this.foxexchangeCss = document.getElementById('foxexchangeDefault1_id');

    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.binanceExchanger();
    this.exolixExchanger();
    this.changeNowExchanger();
    this.simpleSwapExchanger();
    this.stealthEXExchanger();
    this.godexExchanger();
    this.changellyExchanger();
    this.switChainExchanger();
    this.foxExchange();
    this.letsExchange();
    this.nExchange();
    this.changeHeroExchange();
  }

  open(content) {
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  open2(content) {
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    // this.toastService.showInfo("Testing Version", "Warning");
    // this.name = ''
    // ------------------------------------- get coins data from mysql -------------------------------------------------------------------------

    this.http.get(`${swapspaceAPI}/currencies`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((a: any) => {
      a.forEach(b => {
        var abc = b.code
        // console.log("Hello", JSON.stringify(b));
        this.getCoinData_A.push(abc)
        this.getCoinData_B.push(abc)
        // this.coinNetwork_A.push(b.network)
        // this.coinNetwork_B.push(b.network)
      })
    }, (e) => {
      Swal.fire({
        title: 'Error',
        text: `Something went wrong, Try again later`,
        icon: 'error',
        confirmButtonText: 'Ok',
        width: '400'
      })
    }, () => {
      this.getCoinData_B.push('bigb');
      this.getCoinData_A.forEach(a => {
        this.mlist.push(a)
      })
      this.getCoinData_B.forEach(a => {
        this.mlistB.push(a)
      })
    })
    this.firstselect = this.drp_1;
    this.secondselect = this.drp_2;


    // ------------------------------------------------------------------------------------------------------------------------------------------
  }

  // --------------------------------------------------------------------------------------------------------------------------------------------
  search() {
    // console.log("mlist A",this.mlist+'\n\nname : ',this.name);
    this.getCoinData_A = []
    //push mlist(all coins data) to getCoinData_A array
    this.mlist.forEach(a => {
      this.getCoinData_A.push(a)
      return a
    })

    if (this.secondselect == "BIGB") {
      if (this.firstselect == 'inr' || this.firstselect == 'bnb' || this.firstselect == 'btc' || this.firstselect == 'trx' || this.firstselect == 'eth') {
        this.getCoinData_A = []
        this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      }
      this.firstselect = 'INR'
      this.getCoinData_A = []
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      this.bigb_Conversion(this.firstselect);
    }
    if (this.name.toLocaleLowerCase() == '' && this.secondselect == 'BIGB') {
      this.getCoinData_A = []
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
    } else if (this.name.toLocaleLowerCase() == '') {   //if we not type anything in search box then also all coin list will show
      this.mlist.forEach(a => {
        this.getCoinData_A.push(a)
        return a
      })
    }
    //match typed coin in search list
    console.log('getCoinData_A' + JSON.stringify(this.getCoinData_A))
    this.getCoinData_A = this.getCoinData_A.filter((res: any) => {
      // console.log('hiiiiiiiii  :  '+res+'   byeeee : ',res.match(this.name.toLocaleLowerCase()))
      return res.match(this.name.toLocaleLowerCase())
    });
  }

  search2() {
    // console.log("MLIST 2..",this.mlistB)
    this.getCoinData_B = []
    this.mlistB.forEach(a => {
      this.getCoinData_B.push(a)
      return a
    })
    if (this.secondselect == 'BIGB') {
      // console.log("hi same secondselect")
      this.defaultVal2 = 0;
      this.firstselect = 'INR'
      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')

      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
    }

    if (this.name2.toLocaleLowerCase() == '') {
      this.mlistB.forEach(a => {
        this.getCoinData_B.push(a)
        return a
      })
    }
    this.getCoinData_B = this.getCoinData_B.filter((res: any) => {
      return res.match(this.name2.toLocaleLowerCase())
    });
  }

  selectclick(data1: any) {
    // console.log('selectclick' + this.secondselect + "\n" + data1)
    this.mlist.forEach(a => {
      this.getCoinData_A.push(a)
    })
    this.firstselect = data1.toUpperCase();
    this.modalService.dismissAll()
    this.name = ''
    if (this.secondselect == "BIGB") {
      if (data1 == 'inr' || data1 == 'bnb' || data1 == 'btc' || data1 == 'trx' || data1 == 'eth') {
        this.getCoinData_A = []
        this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
        // this.getCoinData_A.push('inr')
      }
      this.bigb_Conversion(this.firstselect)
    }
    else {
      this.binanceExchanger();
      this.exolixExchanger();
      this.changeNowExchanger();
      this.simpleSwapExchanger();
      this.stealthEXExchanger();
      this.godexExchanger();
      this.changellyExchanger();
      this.switChainExchanger();
      this.foxExchange();
      this.letsExchange();
      this.nExchange();
      this.changeHeroExchange();
    }
    if (this.firstselect == this.secondselect) {
      if (this.firstselect != 'BTC') {
        this.secondselect = 'BTC'
      }
      else {
        this.secondselect = 'ETH'
      }
    }
  }

  selectclick2(data1: any) {
    this.mlistB.forEach(a => {
      this.getCoinData_B.push(a)
    })
    this.secondselect = data1.toUpperCase();
    this.modalService.dismissAll()
    this.name2 = ''
    if (data1 == 'bigb') {
      this.firstselect = 'INR'
      this.getCoinData_A = []
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      this.bigb_Conversion(data1);

      this.BinanceEnDis = 0;
      this.DisabledExolix = 0;
      this.ChangeNowEnDis = 0;
      this.SimSwapEnDis = 0;
      this.StealthEXEnDis = 0;
      this.GodexEnDis = 0;
      this.ChangellyEnDis = 0;
      this.swithainEnDis = 0;
      this.LetsExchangeEnDis = 0;
      this.NExchngerEnDis = 0;
      this.changeheroEnDis = 0;
      this.foxexchangeEnDis = 0;

      this.binanceCss.style.color = 'red';
      this.exolixCss.style.color = 'red';
      this.changeNowCss.style.color = 'red';
      this.simpleSwapCss.style.color = 'red';
      this.stealthEXCss.style.color = 'red';
      this.godexCss.style.color = 'red';
      this.changellyCss.style.color = 'red';
      this.switchainCss.style.color = 'red';
      this.letsExchangeCss.style.color = 'red';
      this.NexchangeCss.style.color = 'red';
      this.changeHeroCss.style.color = 'red';
      this.foxexchangeCss.style.color = 'red';

      this.binanceCss.style.fontSize = 'large';
      this.exolixCss.style.fontSize = 'large';
      this.changeNowCss.style.fontSize = 'large';
      this.simpleSwapCss.style.fontSize = 'large';
      this.stealthEXCss.style.fontSize = 'large';
      this.godexCss.style.fontSize = 'large';
      this.changellyCss.style.fontSize = 'large';
      this.switchainCss.style.fontSize = 'large';
      this.letsExchangeCss.style.fontSize = 'large';
      this.NexchangeCss.style.fontSize = 'large';
      this.changeHeroCss.style.fontSize = 'large';
      this.foxexchangeCss.style.fontSize = 'large';

      this.godexCalVal = 'Not Available'
      this.binanceCalVal = 'Not Available';
      this.ChangeNowValue = 'Not Available';
      this.simpleSwapCal = 'Not Available';
      this.exolicCalVal = 'Not Available';
      this.stealthExValue = 'Not Available';
      this.godexCalVal = 'Not Available';
      this.changellyCalVal = 'Not Available';
      this.switchainCalVal = 'Not Available';
      this.letsexchangeCalVal = 'Not Available';
      this.NexchangeCalVal = 'Not Available';
      this.changeheroCalVal = 'Not Available';
      this.foxexchangeCalVal = 'Not Available';

      this.cotoexDiv = 1;
    }
    else {
      this.cotoexDiv = 0;
      if (this.firstselect == 'INR') {
        this.firstselect = 'ETH';
      }

      this.getCoinData_A = []
      this.getCoinData_B = []
      this.http.get(`${swapspaceAPI}/currencies`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: swapspaceAPIkey
        }
      }).subscribe((a: any) => {
        a.forEach(b => {
          var abc = b.code
          console.log("Hello", JSON.stringify(b));
          this.getCoinData_A.push(abc)
          this.getCoinData_B.push(abc)
          // this.coinNetwork_A.push(b.network)
          // this.coinNetwork_B.push(b.network)
        })
      }, (e) => {
        Swal.fire({
          title: 'Error',
          text: `Something went wrong, Try again later`,
          icon: 'error',
          confirmButtonText: 'Ok',
          width: '400'
        })
      }, () => {
        this.getCoinData_A.forEach(a => {
          this.mlist.push(a)
        })
        this.getCoinData_B.forEach(a => {
          this.mlistB.push(a)
        })

      })
      if (this.firstselect == this.secondselect) {
        if (this.firstselect != 'ETH') {
          this.secondselect = 'ETH'
        }
        else {
          this.secondselect = 'BTC'
        }
      }
      this.binanceExchanger();
      this.exolixExchanger();
      this.changeNowExchanger();
      this.simpleSwapExchanger();
      this.stealthEXExchanger();
      this.godexExchanger();
      this.changellyExchanger();
      this.switChainExchanger();
      this.foxExchange();
      this.letsExchange();
      this.nExchange();
      this.changeHeroExchange();

    }
  }

  // ---------------------------------------------------------------------- End of change function of dropdown 2 -------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------- input function of first dropdown ------------------------------------------------------------------------------------------------------------------------------------------------------------------
  myFunction() {
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()
    if (this.secondselect != 'BIGB') {
      this.binanceExchanger();
      this.exolixExchanger();
      this.changeNowExchanger();
      this.stealthEXExchanger();
      this.simpleSwapExchanger();
      this.godexExchanger();
      this.changellyExchanger();
      this.switChainExchanger();
      this.foxExchange();
      this.letsExchange();
      this.nExchange();
      this.changeHeroExchange();
    }
    else {
      this.bigb_Conversion(this.firstselect);
    }
    //  function calling 
  }

  //  ----------------------------------------------------------- end of input function of dropdown 1-----------------------------------------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------- Exchange button function ----------------------------------------------------------------------------------------------------------------------- 
  submitExchange(table_id) {
    // console.log('event exchange' + table_id)
    this.route.navigateByUrl('/step2', { state: { table_id: table_id, drp_1: this.firstselect, drp_2: this.secondselect, amt: this.usernameElement.nativeElement.value } });

    // this.route.navigateByUrl(`/step2/${table_id}/${this.firstselect}/${this.secondselect}/${this.usernameElement.nativeElement.value}`).then(a => {
    //   console.log('hiiiii' + JSON.stringify(a))
    // }).catch(b => {
    //   console.log('route error' + JSON.stringify(b))
    // })
  }
  //  ------------------------------------------------------------- end of exchange button ----------------------------------------------------------------------------------

  binanceExchanger() {
    this.load = 0
    this.binanceCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=binance&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((n: any) => {
      this.BinanceEnDis = 0;
      this.binanceCalVal = 'Not Available';
      this.binanceCss.style.color = 'red'
      this.binanceCss.style.fontSize = 'large'
      this.binanceCalVal1 = 0

      if (this.usernameElement.nativeElement.value < n[0].min) {

        // console.log("switch chain if 1 --------------------------------" + n[0].min)
        this.BinanceEnDis = 0;
        this.binanceCss.style.color = 'red'
        this.binanceCalVal = 'Min : ' + n[0].min;
        this.binanceCalVal1 = 0
      }
      else {

        if (n[0].toAmount == 0) {
          this.BinanceEnDis = 0;
          this.binanceCalVal = 'Not Available';
          this.binanceCss.style.color = 'red'
          this.binanceCss.style.fontSize = 'large'
          this.binanceCalVal1 = 0
        } else {
          this.binanceCalVal = (n[0].toAmount).toFixed(8)
          // console.log("\n-------" + JSON.stringify(n))
          this.BinanceEnDis = 1;
          this.binanceCss.style.color = '#00c162'
          this.binanceCalVal = (n[0].toAmount).toFixed(8)
          this.binanceCalVal1 = (n[0].toAmount).toFixed(8)
          this.load = 1
        }
      }
    }, (e) => {

      this.BinanceEnDis = 0;
      this.binanceCalVal = 'Not Available';
      this.binanceCss.style.color = 'red'
      this.binanceCss.style.fontSize = 'large'
      this.binanceCalVal1 = 0

    }, () => {

      if (this.binanceCalVal == 0) {
        this.BinanceEnDis = 0;
        this.binanceCalVal = 'Not Available';
        this.binanceCss.style.color = 'red'
        this.binanceCss.style.fontSize = 'large'
        this.binanceCalVal1 = 0
      }

    })
  }

  //  -------------------------------------------------------- Exolix function -----------------------------------------------------------

  exolixExchanger() {
    this.load = 0
    this.exolicCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=exolix&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((ex: any) => {
      this.DisabledExolix = 0;
      this.exolicCalVal = 'Not Available';
      this.exolixCss.style.color = 'red'
      this.exolixCss.style.fontSize = 'large'
      this.exolicCalVal1 = 0
      if (this.usernameElement.nativeElement.value < ex[0].min) {
        // console.log("\n----")
        this.DisabledExolix = 0;
        this.exolixCss.style.color = 'red'
        this.exolicCalVal = 'Min : ' + ex[0].min;
        this.exolicCalVal1 = 0
      }
      else {
        if (ex[0].toAmount == 0) {
          this.DisabledExolix = 0;
          this.exolicCalVal = 'Not Available';
          this.exolixCss.style.color = 'red'
          this.exolixCss.style.fontSize = 'large'
          this.exolicCalVal1 = 0

        } else {
          this.exolicCalVal = (ex[0].toAmount).toFixed(8)
          // console.log("\n-------")
          this.DisabledExolix = 1;
          this.exolixCss.style.color = '#00c162'
          this.exolicCalVal = (ex[0].toAmount).toFixed(8)
          this.exolicCalVal1 = (ex[0].toAmount).toFixed(8)
          this.load = 1
        }
      }
    }, (e) => {
      this.DisabledExolix = 0;
      this.exolicCalVal = 'Not Available';
      this.exolixCss.style.color = 'red'
      this.exolixCss.style.fontSize = 'large'
      this.exolicCalVal1 = 0
    }, () => {

    })

  }

  // -------------------------------------------------- End of Exolix --------------------------------------------------------------------


  // -------------------------------------------------- switchain ------------------------------------------------------------------
  switChainExchanger() {
    this.load = 0
    this.switchainCalVal = 0
    this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=switchain&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((sc: any) => {
      this.load = 0
      this.swithainEnDis = 0;
      this.switchainCalVal = 'Not Available';
      this.switchainCss.style.color = 'red'
      this.switchainCss.style.fontSize = 'large'
      this.switchainCalVal1 = 0
      if (this.usernameElement.nativeElement.value < sc[0].min) {
        // console.log("switch chain if 1 --------------------------------" + sc[0].min)
        this.swithainEnDis = 0;
        this.switchainCss.style.color = 'red'
        this.switchainCalVal = 'Min : ' + sc[0].min;
        this.switchainCalVal1 = 0
      }
      else {
        if (sc[0].toAmount == 0) {
          this.swithainEnDis = 0;
          this.switchainCalVal = 'Not Available';
          this.switchainCss.style.color = 'red'
          this.switchainCss.style.fontSize = 'large'
          this.switchainCalVal1 = 0

        } else {
          this.switchainCalVal = (sc[0].toAmount).toFixed(8)
          // console.log("\n-------" + JSON.stringify(sc))
          this.swithainEnDis = 1;
          this.switchainCss.style.color = '#00c162'
          this.switchainCalVal = (sc[0].toAmount).toFixed(8)
          this.switchainCalVal1 = (sc[0].toAmount).toFixed(8)
          this.load = 1
        }
      }
    }, (e) => {
      this.swithainEnDis = 0;
      this.switchainCalVal = 'Not Available';
      this.switchainCss.style.color = 'red'
      this.switchainCss.style.fontSize = 'large'
      this.switchainCalVal1 = 0
    }, () => {

    })
  }
  // --------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------- changelly ------------------------------------------------------------------

  changellyExchanger() {
    this.load = 0
    this.changellyCalVal = 0
    this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=changelly&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((cl: any) => {
      this.ChangellyEnDis = 0;
      this.changellyCss.style.color = 'red'
      this.changellyCss.style.fontSize = 'large'
      this.changellyCalVal = 'Not Available'
      this.changellyCalVal1 = 0
      if (this.usernameElement.nativeElement.value < cl[0].min) {
        // console.log("\n----")
        this.ChangellyEnDis = 0;
        this.changellyCss.style.color = 'red'
        this.changellyCalVal = 'Min : ' + cl[0].min;
        this.changellyCss.style.fontSize = 'large'
        this.changellyCalVal1 = 0
      }
      else {
        if (cl[0].toAmount == 0) {
          this.ChangellyEnDis = 0;
          this.changellyCss.style.color = 'red'
          this.changellyCss.style.fontSize = 'large'
          this.changellyCalVal = 'Not Available'
          this.changellyCalVal1 = 0
        }
        else {
          this.changellyCalVal = (cl[0].toAmount).toFixed(8)
          // console.log("\n-------")
          this.ChangellyEnDis = 1;
          this.changellyCss.style.color = '#00c162'
          this.changellyCss.style.fontSize = 'large'
          this.changellyCalVal = (cl[0].toAmount).toFixed(8)
          this.changellyCalVal1 = (cl[0].toAmount).toFixed(8)
          this.load = 1
        }

      }
    }, (e) => {
      this.ChangellyEnDis = 0;
      this.ChangellyEnDis = 0;
      this.changellyCss.style.color = 'red'
      this.changellyCss.style.fontSize = 'large'
      this.changellyCalVal = 'Not Available'
      this.changellyCalVal1 = 0
    }, () => {

    })
  }

  // --------------------------------------------------------------------------------------------------------------------------------

  //  ------------------------------------------------------------ Change Now ------------------------------------------------------------

  changeNowExchanger() {
    this.load = 0
    this.ChangeNowValue = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=changenow&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((cn: any) => {
      this.ChangeNowEnDis = 0;
      this.changeNowCss.style.color = 'red'
      this.changeNowCss.style.fontSize = 'large'
      this.ChangeNowValue = 'Not Available'
      this.ChangeNowValue1 = 0
      if (this.usernameElement.nativeElement.value < cn[0].min) {
        // console.log("\n----")
        this.ChangeNowEnDis = 0;
        this.changeNowCss.style.color = 'red'
        this.ChangeNowValue = 'Min : ' + cn[0].min;
        this.ChangeNowValue1 = 0
      }
      else {
        if (cn[0].toAmount == 0) {
          this.ChangeNowEnDis = 0;
          this.changeNowCss.style.color = 'red'
          this.changeNowCss.style.fontSize = 'large'
          this.ChangeNowValue = 'Not Available'
          this.ChangeNowValue1 = 0
          this.load = 1
        }
        else {
          this.ChangeNowValue = (cn[0].toAmount).toFixed(8)
          // console.log("\n-------")
          this.ChangeNowEnDis = 1;
          this.changeNowCss.style.color = '#00c162'
          this.ChangeNowValue = (cn[0].toAmount).toFixed(8)
          this.ChangeNowValue1 = (cn[0].toAmount).toFixed(8)
        }
      }
    }, (e) => {
      this.ChangeNowEnDis = 0;
      this.changeNowCss.style.color = 'red'
      this.changeNowCss.style.fontSize = 'large'
      this.ChangeNowValue = 'Not Available'
      this.ChangeNowValue1 = 0
    }, () => {

    })

  }
  //  --------------------------------------------------------- End of Change Now------------------------------------------------


  // ------------------------------------------------------------- swapspace/Godex ----------------------------------------------

  godexExchanger() {
    this.load = 0
    this.godexCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=godex&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((ab: any) => {
      this.GodexEnDis = 0;
      this.godexCss.style.color = 'red';
      this.godexCss.style.fontSize = 'large';
      this.godexCalVal = 'Not Available'
      this.godexCalVal1 = 0
      if (this.usernameElement.nativeElement.value < ab[0].min) {
        // console.log("\n----")
        this.GodexEnDis = 0;
        this.godexCss.style.color = 'red'
        this.godexCalVal = 'Min : ' + ab[0].min;
        this.godexCalVal1 = 0
      }
      else {
        if (ab[0].toAmount == 0) {
          this.GodexEnDis = 0;
          this.godexCss.style.color = 'red';
          this.godexCss.style.fontSize = 'large';
          this.godexCalVal = 'Not Available'
          this.godexCalVal1 = 0
        }
        else {
          this.godexCalVal = (ab[0].toAmount).toFixed(8)
          // console.log("\n-------")
          this.GodexEnDis = 1;
          this.godexCss.style.color = '#00c162'
          this.godexCalVal = (ab[0].toAmount).toFixed(8)
          this.godexCalVal1 = (ab[0].toAmount).toFixed(8)
          this.load = 1
        }
      }
    }, (e) => {
      this.GodexEnDis = 0;
      this.godexCss.style.color = 'red';
      this.godexCss.style.fontSize = 'large';
      this.godexCalVal = 'Not Available'
      this.godexCalVal1 = 0
    }, () => {

    })
  }


  // ----------------------------------------------------------------------------------------------------------------------------

  //  -------------------------------------------------------- Simple Swap Function ---------------------------------------------
  simpleSwapExchanger() {
    this.load = 0
    this.simpleSwapCal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()
    this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=simpleswap&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((sswap: any) => {
      this.SimSwapEnDis = 0;
      this.simpleSwapCss.style.color = 'red';
      this.simpleSwapCss.style.fontSize = 'large';
      this.simpleSwapCal = 'Not Available'
      this.simpleSwapCal1 = 0
      if (this.usernameElement.nativeElement.value < sswap[0].min) {
        // console.log("\n----")
        this.SimSwapEnDis = 0;
        this.simpleSwapCss.style.color = 'red'
        this.simpleSwapCal = 'Min : ' + sswap[0].min;
        this.simpleSwapCal1 = 0
      }
      else {
        if (sswap[0].toAmount == 0) {
          this.SimSwapEnDis = 0;
          this.simpleSwapCss.style.color = 'red';
          this.simpleSwapCss.style.fontSize = 'large';
          this.simpleSwapCal = 'Not Available'
          this.simpleSwapCal1 = 0
        }
        else {
          this.simpleSwapCal = sswap[0].toAmount.toFixed(8)
          // console.log("\n-------")
          this.SimSwapEnDis = 1;
          this.simpleSwapCss.style.color = '#00c162'
          this.simpleSwapCal = sswap[0].toAmount.toFixed(8)
          this.simpleSwapCal1 = sswap[0].toAmount.toFixed(8)
          this.load = 1
        }
      }
    }, (e) => {
      this.SimSwapEnDis = 0;
      this.simpleSwapCss.style.color = 'red';
      this.simpleSwapCss.style.fontSize = 'large';
      this.simpleSwapCal = 'Not Available'
      this.simpleSwapCal1 = 0
    }, () => {

    })

  }
  // ---------------------------------------------------------- End of Simple Swap ----------------------------------------------

  // ---------------------------------------------------------- stealthEX --------------------------------------------------------
  stealthEXExchanger() {
    this.load = 0
    this.stealthExValue = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=stealthex&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((stealthex: any) => {
      this.StealthEXEnDis = 0;
      this.stealthEXCss.style.color = 'red'
      this.stealthExValue = 'Not Available'
      this.stealthEXCss.style.fontSize = 'large'
      this.stealthExValue1 = 0
      if (this.usernameElement.nativeElement.value < stealthex[0].min) {
        // console.log("\n----")
        this.StealthEXEnDis = 0;
        this.stealthEXCss.style.color = 'red'
        this.stealthExValue = 'Min : ' + stealthex[0].min;
        this.stealthExValue1 = 0
      }
      else {
        if (stealthex[0].toAmount == 0) {
          this.StealthEXEnDis = 0;
          this.stealthEXCss.style.color = 'red'
          this.stealthExValue = 'Not Available'
          this.stealthEXCss.style.fontSize = 'large'
          this.stealthExValue1 = 0
        }
        else {
          this.stealthExValue = (stealthex[0].toAmount).toFixed(8)
          // console.log("\n-------")
          this.StealthEXEnDis = 1;
          this.stealthEXCss.style.color = '#00c162'
          this.stealthExValue = (stealthex[0].toAmount).toFixed(8)
          this.stealthExValue1 = (stealthex[0].toAmount).toFixed(8)
          this.load = 1
        }
      }
    }, (e) => {
      this.StealthEXEnDis = 0;
      this.stealthEXCss.style.color = 'red'
      this.stealthExValue = 'Not Available'
      this.stealthEXCss.style.fontSize = 'large'
      this.stealthExValue1 = 0
    }, () => {

    })
  }

  foxExchange() {
    this.load = 0
    this.foxexchangeCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=foxexchange&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((fox: any) => {
      this.foxexchangeEnDis = 0;
      this.foxexchangeCss.style.color = 'red'
      this.foxexchangeCss.style.fontSize = 'large'
      this.foxexchangeCalVal = `Not Available`
      this.foxexchangeCalVal1 = 0
      if (this.usernameElement.nativeElement.value < fox[0].min) {
        // console.log("switch chain if 1 --------------------------------" + fox[0].min)
        this.foxexchangeEnDis = 0;
        this.foxexchangeCss.style.color = 'red'
        this.foxexchangeCalVal = 'Min : ' + fox[0].min;
        this.foxexchangeCalVal1 = 0
      }
      else {
        if (fox[0].toAmount == 0) {
          this.foxexchangeEnDis = 0;
          this.foxexchangeCss.style.color = 'red'
          this.foxexchangeCss.style.fontSize = 'large'
          this.foxexchangeCalVal = `Not Available`
          this.foxexchangeCalVal1 = 0
        }
        else {
          this.foxexchangeCalVal = fox[0].toAmount.toFixed(8)
          // console.log("\n foxexchange -------" + JSON.stringify(fox))
          this.foxexchangeEnDis = 1;
          this.foxexchangeCss.style.color = '#00c162'
          this.foxexchangeCalVal = fox[0].toAmount.toFixed(8)
          this.foxexchangeCalVal1 = fox[0].toAmount.toFixed(8)
          this.load = 1
        }
      }
    }, (e) => {
      this.foxexchangeEnDis = 0;
      this.foxexchangeCss.style.color = 'red'
      this.foxexchangeCss.style.fontSize = 'large'
      this.foxexchangeCalVal = `Not Available`
      this.foxexchangeCalVal1 = 0
    }, () => {

    })
  }

  nExchange() {
    this.load = 0
    this.NexchangeCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()
    this.NExchngerEnDis = 0;
    this.NexchangeCalVal = 'Not Available';
    this.NexchangeCss.style.color = 'red'
    this.NexchangeCss.style.fontSize = 'large'
    this.NexchangeCalVal1 = 0
    // this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=nexchange&fixed=false`, {
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     Authorization: swapspaceAPIkey
    //   }
    // }).subscribe((n: any) => {
    //   this.NExchngerEnDis = 0;
    //   this.NexchangeCalVal = 'Not Available';
    //   this.NexchangeCss.style.color = 'red'
    //   this.NexchangeCss.style.fontSize = 'large'
    //   this.NexchangeCalVal1 = 0
    //   if (this.usernameElement.nativeElement.value < n[0].min) {
    //     // console.log("switch chain if 1 --------------------------------" + n[0].min)
    //     this.NExchngerEnDis = 0;
    //     this.NexchangeCss.style.color = 'red'
    //     this.NexchangeCalVal = 'Min : ' + n[0].min;
    //     this.NexchangeCalVal1 = 0
    //   }
    //   else {
    //     if (n[0].toAmount == 0) {
    //       this.NExchngerEnDis = 0;
    //       this.NexchangeCalVal = 'Not Available';
    //       this.NexchangeCss.style.color = 'red'
    //       this.NexchangeCss.style.fontSize = 'large'
    //       this.NexchangeCalVal1 = 0

    //     } else {
    //       this.NexchangeCalVal = (n[0].toAmount).toFixed(8)
    //       // console.log("\n-------" + JSON.stringify(n))
    //       this.NExchngerEnDis = 1;
    //       this.NexchangeCss.style.color = '#00c162'
    //       this.NexchangeCalVal = (n[0].toAmount).toFixed(8)
    //       this.NexchangeCalVal1 = (n[0].toAmount).toFixed(8)
    //     }
    //   }
    // }, (e) => {
    //   this.NExchngerEnDis = 0;
    //   this.NexchangeCalVal = 'Not Available';
    //   this.NexchangeCss.style.color = 'red'
    //   this.NexchangeCss.style.fontSize = 'large'
    // }, () => {

    // })
  }

  changeHeroExchange() {
    this.changeheroCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=changehero&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((cch: any) => {
      this.changeheroEnDis = 0;
      this.changeHeroCss.style.color = 'red'
      this.changeHeroCss.style.fontSize = 'large'
      this.changeheroCalVal = 'Not Available';
      this.changeheroCalVal1 = 0
      if (this.usernameElement.nativeElement.value < cch[0].min) {
        // console.log("switch chain if 1 --------------------------------" + cch[0].min)
        this.changeheroEnDis = 0;
        this.changeHeroCss.style.color = 'red'
        this.changeheroCalVal = 'Min : ' + cch[0].min;
        this.changeheroCalVal1 = 0
      }
      else {
        if (cch[0].toAmount == 0) {
          this.changeheroEnDis = 0;
          this.changeHeroCss.style.color = 'red'
          this.changeHeroCss.style.fontSize = 'large'
          this.changeheroCalVal = 'Not Available';
          this.changeheroCalVal1 = 0
        } else {
          //  alert(cch[0].toAmount.toFixed(8))
          this.changeheroCalVal = cch[0].toAmount.toFixed(8)
          // console.log("\n-------" + JSON.stringify(cch))
          this.changeheroEnDis = 1;
          this.changeHeroCss.style.color = '#00c162'
          this.changeheroCalVal = (cch[0].toAmount).toFixed(8)
          this.changeheroCalVal1 = (cch[0].toAmount).toFixed(8)
        }
      }
    }, (e) => {
      this.changeheroEnDis = 0;
      this.changeHeroCss.style.color = 'red'
      this.changeHeroCss.style.fontSize = 'large'
      this.changeheroCalVal = 'Not Available';
    }, () => {

    })
  }

  letsExchange() {
    this.letsexchangeCalVal = 0
    this.toLower1 = String(this.firstselect).toLowerCase()
    this.toLower2 = String(this.secondselect).toLowerCase()

    this.http.get(`${swapspaceAPI}/amounts?amount=${this.usernameElement.nativeElement.value}&fromCurrency=${this.toLower1}&toCurrency=${this.toLower2}&fromNetwork=mainnet&toNetwork=mainnet&partner=letsexchange&fixed=false`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((le: any) => {
      this.LetsExchangeEnDis = 0;
      this.letsexchangeCalVal = 'Not Available'
      this.letsExchangeCss.style.color = 'red'
      this.letsExchangeCss.style.fontSize = 'large'
      this.letsexchangeCalVal1 = 0
      if (this.usernameElement.nativeElement.value < le[0].min) {
        // console.log("switch chain if 1 --------------------------------" + le[0].min)
        this.LetsExchangeEnDis = 0;
        this.letsExchangeCss.style.color = 'red'
        this.letsexchangeCalVal = 'Min : ' + le[0].min;
        this.letsexchangeCalVal1 = 0
      }
      else {
        if (le[0].toAmount == 0) {
          this.LetsExchangeEnDis = 0;
          this.letsexchangeCalVal = 'Not Available'
          this.letsExchangeCss.style.color = 'red'
          this.letsExchangeCss.style.fontSize = 'large'
          this.letsexchangeCalVal1 = 0

        } else {
          this.letsexchangeCalVal = le[0].toAmount.toFixed(8)
          // console.log("\n-------" + JSON.stringify(le))
          this.LetsExchangeEnDis = 1;
          this.letsExchangeCss.style.color = '#00c162'
          this.letsexchangeCalVal = le[0].toAmount.toFixed(8)
          this.letsexchangeCalVal1 = le[0].toAmount.toFixed(8)
        }
      }
    }, (e) => {
      this.LetsExchangeEnDis = 0;
      this.letsexchangeCalVal = 'Not Available'
      this.letsExchangeCss.style.color = 'red'
      this.letsExchangeCss.style.fontSize = 'large'
    }, () => {

    })
  }
  // -----------------------------------------------------------------------------------------------------------------------------


  secondDropdwnVal() {
    if (this.secondselect != "BIGB") {

      this.allVal = [];

      this.allVal = [this.binanceCalVal1, this.foxexchangeCalVal1, this.ChangeNowValue1, this.changeheroCalVal1,
      this.changellyCalVal1, this.letsexchangeCalVal1, this.stealthExValue1, this.godexCalVal1, this.exolicCalVal1,
      this.switchainCalVal1, this.simpleSwapCal1, this.NexchangeCalVal1]

      // console.log('\n allval array' + JSON.stringify(this.allVal))

      var filteredAry: [] = this.allVal.filter(e => e !== 0)

      // console.log('\n filteredAry' + filteredAry)

      this.inputCalVal = Math.max(...filteredAry)
      // console.log('\n 2nd inputcalval' + this.inputCalVal)

      if (filteredAry.length == 0) {
        this.inputCalVal = "Select min amount "
      }
    }
  }



  bigbExchange() {
    console.log('hiiiii')
    // console.log('from: ' + this.firstselect + ' to: ' + this.secondselect + 'amount: ' + this.usernameElement.nativeElement.value)
    if (this.firstselect == 'INR') {
      // this.route.navigateByUrl(`/bigb-step2/${this.firstselect}/${this.usernameElement.nativeElement.value}`);
      this.route.navigateByUrl('/bigb-step2', { state: { drp_1: this.firstselect, amt: this.usernameElement.nativeElement.value } });

    }
    else {
      // this.route.navigateByUrl(`/bigb-step2/${this.firstselect}/${this.usernameElement.nativeElement.value}`);
      this.route.navigateByUrl('/bigb-step2', { state: { drp_1: this.firstselect, amt: this.usernameElement.nativeElement.value } });

    }
  }

  bigb_Conversion(data) {
    this.cotoexCss = document.getElementById('cotoexDefault1_id');

    // this.inputCalVal = "Not Available"
    // this.cotoexcalval = this.inputCalVal
    this.toLower1 = String(data).toUpperCase()
    this.inputCalVal = 'Not Available';

    if (this.firstselect == 'INR') {
      var bigbPrice;
      this.mysql.getBigbPrice().subscribe((a: any) => {
        this.mysql.getMinRateBigb().subscribe((minVal: any) => {
          console.log('min value', JSON.stringify)

          console.log('USD price' + 'inr : ' + a.INRPrice + 'bigb : ' + a.price + '\n input: ', this.usernameElement.nativeElement.value)
          bigbPrice = a.INRPrice;
          console.log('bigb to inr price 1 : ' + bigbPrice)
          if (this.usernameElement.nativeElement.value < minVal.minInr) {   //min amount should be 1000
            // this.disableBuyBigh = ' '
            if (document.getElementById("cotoexDefault1_id") != null) {
              document.getElementById('cotoexDefault1_id').style.color = 'red';
            }
            this.inputCalVal = `min: ${minVal.minInr}`;
            this.CotoexEnDis = 0
            this.cotoexcalval = this.inputCalVal
          } else if (this.usernameElement.nativeElement.value > minVal.maxInr) {
            if (document.getElementById("cotoexDefault1_id") != null) {
              document.getElementById('cotoexDefault1_id').style.color = 'red';
            }
            this.inputCalVal = `max: ${minVal.maxInr}`;
            this.cotoexcalval = this.inputCalVal
          }
          else {
            // this.disableBuyBigh = 'notDisable'
            if (document.getElementById("cotoexDefault1_id") != null) {
              document.getElementById('cotoexDefault1_id').style.color = '#00c162';
            }
            this.CotoexEnDis = 1
            this.inputCalVal = this.usernameElement.nativeElement.value / bigbPrice
            this.cotoexcalval = this.inputCalVal
            console.log('default price : ' + this.inputCalVal)
          }
        }, (e) => {
          this.CotoexEnDis = 0
          Swal.fire({
            title: 'Error',
            text: `Something went wrong, Try again later`,
            icon: 'error',
            confirmButtonText: 'Ok',
            width: '400'
          })
        }, () => {

        })
      }, (e) => {
        this.CotoexEnDis = 0
        Swal.fire({
          title: 'Error',
          text: `Something went wrong, Try again later`,
          icon: 'error',
          confirmButtonText: 'Ok',
          width: '400'
        })
      })
    }
    else {
      if (this.firstselect == 'BTC' && this.firstselect == 'BNB' && this.firstselect == 'ETH') {
        this.defaultVal = 0.1;
      }
      this.http.get(`${binanceAPI}/avgPrice?symbol=${this.firstselect}USDT`).subscribe(b1 => {
        this.currencyPrice1 = b1.valueOf()
        this.currencyPrice = this.currencyPrice1.price * this.usernameElement.nativeElement.value;
        console.log('currency price', JSON.stringify(b1))
      }, (e) => {
        this.CotoexEnDis = 0
        Swal.fire({
          title: 'Error',
          text: `Something went wrong, Try again later`,
          icon: 'error',
          confirmButtonText: 'Ok',
          width: '400'
        })
      }, () => {
        this.mysql.getBigbPrice().subscribe((b: any) => {
          this.mysql.minmaxCoinpayment(this.firstselect).subscribe((minCP: any) => {
            console.log('\nb.INRPrice : ', b.INRPrice)

            this.BIGBPrice = b.dollarPrice;
            var calculate2 = this.currencyPrice / this.BIGBPrice
            var minAmtBigb = this.BIGBPrice / this.currencyPrice1.price
            var minCoinp = Number(minCP.min)

            if (this.usernameElement.nativeElement.value < minCoinp || this.usernameElement.nativeElement.value < minAmtBigb) {
              console.log('hi 1')
              if (minAmtBigb < minCoinp) {
                console.log('hi 2')
                if (document.getElementById("cotoexDefault1_id") != null) {
                  document.getElementById('cotoexDefault1_id').style.color = 'red';
                }
                this.CotoexEnDis = 0
                this.inputCalVal = `min : ${minCoinp}`
                this.cotoexcalval = this.inputCalVal
              } else if (minAmtBigb > minCoinp) {
                console.log('hi 3')
                if (document.getElementById("cotoexDefault1_id") != null) {
                  document.getElementById('cotoexDefault1_id').style.color = 'red';
                }
                this.CotoexEnDis = 0
                this.inputCalVal = `min : ${minAmtBigb}`
                this.cotoexcalval = this.inputCalVal
              }
            } else {
              console.log('else 2')
              if (document.getElementById("cotoexDefault1_id") != null) {
                document.getElementById('cotoexDefault1_id').style.color = '#00c162';
              }
              this.CotoexEnDis = 1
              this.inputCalVal = calculate2
              this.cotoexcalval = this.inputCalVal
            }

            // CP min :  0.00300000  calculate3 :  0.0002710280183059834 cal 4 :  0.000004573597808913469  calculate 2 :  218646.24782946665

          }, (e) => {
            this.CotoexEnDis = 0
            Swal.fire({
              title: 'Error',
              text: `Something went wrong, Try again later`,
              icon: 'error',
              confirmButtonText: 'Ok',
              width: '400'
            })
          }, () => {
          })

        }, (e) => {
          this.CotoexEnDis = 0
          Swal.fire({
            title: 'Error',
            text: `Something went wrong, Try again later`,
            icon: 'error',
            confirmButtonText: 'Ok',
            width: '400'
          })
        })
      })
    }
  }
}

