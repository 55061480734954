<!DOCTYPE html>
<html lang="en">

<head>

</head>

<body>
    <app-header></app-header>
    <div class="banner-block">

        <div class="container" style="margin-top:13%">

            <div class="offset-md-2 col-md-8 res_cont" id="formobile">
                <div class="banner-content">
                    <h2>Buy and Sell Cryptocurrency</h2>
                    <h3 style="color: white;">
                        Your Choice Our best price
                    </h3>

                    <!---------------------------------------->
                    <form>
                        <div class="row" id="respview" style="margin-left: 10%;">
                            <div class=" m-2">
                                <!--   col   -->
                                <div class="input-box input-group mb-3 dropdown " id="dropdown1">
                                    <input type="text" class="form-control inputss" value="{{defaultVal}}" name="s" #valueId (input)="myFunction()">
                                    <div class="input-group-append ">
                                        <span class="input-group-text " id="basic-addon2" style="background-color:#1652f0; border:none ;">
                                           
                                            <button class="btn btn-primary" id="selectidd"
                                                style="font-size: 14px;background-color:#1652f0; border:none ;box-shadow: none;"
                                                (click)="open(content)">{{firstselect}}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    fill="currentColor" class="bi bi-caret-down-fill"
                                                    viewBox="0 0 16 16" style="margin-left:15%;">
                                                    <path
                                                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="  m-2">
                                <!--   col   -->
                                <div class="input-box input-group mb-3 dropdown " id="dropdown2">
                                    <input type="text" class="form-control inputss" value="{{inputCalVal}}" name="s" disabled>
                                    <div class="input-group-append ">
                                        <span class="input-group-text " id="basic-addon2" style="background-color:#1652f0; border:none ;">
                                           
                                            <button class="btn btn-primary"
                                                style="font-size: 14px;background-color:#1652f0; border:none ;box-shadow: none; "
                                                (click)="open2(content2)">{{secondselect}}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    fill="currentColor" class="bi bi-caret-down-fill"
                                                    viewBox="0 0 16 16" style="margin-left:15%;">
                                                    <path
                                                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </form>

                    <!------------------------------------------>


                </div>
            </div>

        </div>



        <ng-template #content let-modal style="width:10%;height:10%;">

            <div class="modal-body" role="dialog">
                <form>
                    <div class="form-group">

                        <input autocomplete="off" type="text" id="myInput" placeholder="Search for names.." title="Type in a name" name="name" [(ngModel)]="name" (input)="search()">
                        <div class="sandesh11">
                            <ul id="myUL">

                                <li> <a *ngFor="let coins of getCoinData_A;" [value]="coins" style="cursor: pointer;" (click)="selectclick(coins)">
                                        {{coins}} </a></li>

                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>



        <ng-template #content2 let-modal style="width:10%;height:10%;">

            <div class="modal-body" role="dialog">
                <form>
                    <div class="form-group">

                        <input autocomplete="off" type="text" id="myInput" placeholder="Search for names.." title="Type in a name" name="name" [(ngModel)]="name2" (input)="search2()">
                        <div class="sandesh11">
                            <ul id="myUL">

                                <li> <a *ngFor="let coins of getCoinData_B;" [value]="coins" style="cursor: pointer; " (click)="selectclick2(coins)">
                                        {{coins}}  </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>








        <!-------------simpleswap table-------------------->

        <div class="table mt-5" data-v-ae22eaf8 data-v-b1289758>

            <div class="column col-12 col-xl-9" data-v-49591a1a data-v-b1289758 id="santable">
                <div class="step1-table step1__table" data-v-8a74673e data-v-b1289758>
                    <div class="" data-v-8a74673e>
                        <div class="row step1-table-head" data-v-a46bbb88 data-v-7fae9ad1 data-v-8a74673e>
                            <div class="column step1-table-head__type col-2 col-lg-1" data-v-49591a1a data-v-7fae9ad1>
                                <div data-v-49591a1a data-v-7fae9ad1 style="color:white;">
                                    Type
                                </div>
                            </div>
                            <div class="column step1-table-head__rate col-6 col-lg-4" data-v-49591a1a data-v-7fae9ad1>

                                <div class="column step1-table-head__type col-2 col-lg-1" data-v-49591a1a data-v-7fae9ad1>
                                    <div data-v-49591a1a data-v-7fae9ad1 style="color:white;">
                                        Rate
                                    </div>
                                </div>
                                <div class="help step1-table-head__rate-helper" data-v-e60acf14 data-v-7fae9ad1>
                                    <!----><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-question-circle fa-w-16 help__icon help__icon_white"
                                        data-v-e60acf14 data-v-e60acf14>
                                        <path fill="currentColor"
                                            d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
                                            data-v-e60acf14 data-v-e60acf14></path>
                                    </svg>
                                </div>
                            </div>
                            <div class="column step1-table-head__partners col-2 col-lg-2" data-v-49591a1a data-v-7fae9ad1>
                                <div data-v-49591a1a data-v-7fae9ad1>
                                    Partners
                                </div>
                            </div>
                            <div class="column step1-table-head__trust d-f col-2 col-lg-2" data-v-49591a1a data-v-7fae9ad1>
                                <div class="step1-table-head__trust-container d-fbc" data-v-49591a1a data-v-7fae9ad1>
                                    <span class="mr-1" data-v-49591a1a data-v-7fae9ad1>
                                        Symbol
                                    </span>
                                </div>
                            </div>
                        </div>



                        <!------------------------------------------------------------------------------------>
                        <div *ngIf="cotoexDiv==1" class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>


                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                        id="cotoexDefault1_id"><b>
                                            {{cotoexcalval}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    COTOEX
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/cotoex_table_logo.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="bigbExchange()" *ngIf="CotoexEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="bigbExchange()" *ngIf="CotoexEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>
                        <!------------------------------------------------------------------------------------>

                        <!------------------------------------------------------------------------------------>
                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>


                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                        id="binanceDefault1_id">
                                        <i class="fa fa-spinner" *ngIf="load==0"></i>
                                        <b *ngIf="load==1">{{binanceCalVal}}</b>
                                    </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    Binance
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/binance.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('binance')" *ngIf="BinanceEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('binance')" *ngIf="BinanceEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>
                        <!------------------------------------------------------------------------------------>


                        <!--------------------------------------------------------------------------------->
                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>

                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                    id="changenowDefault1_id"><i class="fa fa-spinner" *ngIf="load==0"></i>
                                    <b *ngIf="load==1">{{ChangeNowValue}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    ChangeNow
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/change_now.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('changenow')" *ngIf="ChangeNowEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('changenow')" *ngIf="ChangeNowEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>

                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>

                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                    id="stealthEXDefault1_id">
                                    <i class="fa fa-spinner" *ngIf="load==0"></i>
                                    <b *ngIf="load==1">{{stealthExValue}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    StealthEx
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/StealthEX_logo.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('stealthex')" *ngIf="StealthEXEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('stealthex')" *ngIf="StealthEXEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>
                        <!--  -->


                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>

                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                    id="godexDefault1_id">
                                    <i class="fa fa-spinner" *ngIf="load==0"></i>
                                        <b *ngIf="load==1">{{godexCalVal}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    Godex
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/godex.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('godex')" *ngIf="GodexEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('godex')" *ngIf="GodexEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>


                        <!---->


                        <!--  -->

                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>

                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                        id="exolicDefault1_id">
                                        <i class="fa fa-spinner" *ngIf="load==0"></i>
                                        <b *ngIf="load==1">
                                            {{exolicCalVal}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    Exolix
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>
                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/exolix.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('exolix')" *ngIf="DisabledExolix == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('exolix')" *ngIf="DisabledExolix == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>


                        <!---->




                        <!--  -->

                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>

                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                        id="changellyDefault1_id"><i class="fa fa-spinner" *ngIf="load==0"></i>
                                        <b *ngIf="load==1">
                                            {{changellyCalVal}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    Changelly
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/changelly.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('changelly')" *ngIf="ChangellyEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('changelly')" *ngIf="ChangellyEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>


                        <!---->




                        <!--  -->

                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>

                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                        id="switchainDefault1_id"><i class="fa fa-spinner" *ngIf="load==0"></i>
                                        <b *ngIf="load==1">
                                            {{switchainCalVal}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    Switchain
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/switchain.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('switchain')" *ngIf="swithainEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('switchain')" *ngIf="swithainEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>


                        <!---->


                        <!------------------------------------------------------------------------------------>


                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>

                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                        id="simpleSwapDefault1_id"><i class="fa fa-spinner" *ngIf="load==0"></i>
                                        <b *ngIf="load==1">
                                            {{simpleSwapCal}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    Simple Swap
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/simpleswap.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('simpleswap')" *ngIf="SimSwapEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('simpleswap')" *ngIf="SimSwapEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>
                        <!------------------------------------------------------------------------------------>



                        <!------------------------------------------------------------------------------------>


                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>

                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                        id="letsExchangeDefault1_id"><i class="fa fa-spinner" *ngIf="load==0"></i>
                                        <b *ngIf="load==1">
                                            {{letsexchangeCalVal}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    LetsExchange
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/lets_exchange.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('letsexchange')" *ngIf="LetsExchangeEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('letsexchange')" *ngIf="LetsExchangeEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>
                        <!------------------------------------------------------------------------------------>

                        <!------------------------------------------------------------------------------------>
                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>

                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                        id="changeheroDefault1_id"><i class="fa fa-spinner" *ngIf="load==0"></i>
                                        <b *ngIf="load==1">
                                            {{changeheroCalVal}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    ChangeHero
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/changr_hero.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('changehero')" *ngIf="changeheroEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('changehero')" *ngIf="changeheroEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>
                        <!------------------------------------------------------------------------------------>



                        <!------------------------------------------------------------------------------------>
                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>

                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                        id="NExchangeDefault1_id"><i class="fa fa-spinner" *ngIf="load==0"></i>
                                        <b *ngIf="load==1">
                                            {{NexchangeCalVal}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    n.exchange
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/n_exchange.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('nexchange')" *ngIf="NExchngerEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('nexchange')" *ngIf="NExchngerEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>
                        <!------------------------------------------------------------------------------------>


                        <!------------------------------------------------------------------------------------>



                        <!------------------------------------------------------------------------------------>
                        <div class="row step1-table__row" data-v-a46bbb88 data-v-8a74673e>
                            <div class="column d-fcc step1-table__type col-2 col-lg-1" data-v-49591a1a data-v-8a74673e>
                                <!--1st row fst column-->
                                <svg viewBox="0 0 27 20" xmlns="http://www.w3.org/2000/svg" class="icon-floating step1-table__icon" data-v-021c25d6 data-v-8a74673e>
                                    <g data-v-021c25d6>
                                        <path
                                            d="M20.7338 15H21.9042C22.2386 15 22.5731 14.6667 22.5731 14.3333V2.33333C22.5731 2 22.2386 1.66667 21.9042 1.66667H20.7338C20.3994 1.66667 20.0649 2 20.0649 2.33333V14.3333C20.0649 14.6667 20.3994 15 20.7338 15ZM10.7013 15H11.8718C12.2062 15 12.5406 14.6667 12.5406 14.3333V4C12.5406 3.66667 12.2062 3.33333 11.8718 3.33333H10.7013C10.3669 3.33333 10.0325 3.66667 10.0325 4V14.3333C10.0325 14.6667 10.3669 15 10.7013 15ZM15.7175 15H16.888C17.2224 15 17.5568 14.6667 17.5568 14.3333V7.33333C17.5568 7 17.2224 6.66667 16.888 6.66667H15.7175C15.3831 6.66667 15.0487 7 15.0487 7.33333V14.3333C15.0487 14.6667 15.3831 15 15.7175 15ZM25.9172 17.5H2.50812V0.833333C2.50812 0.372917 2.13399 0 1.67208 0H0.836039C0.374128 0 0 0.372917 0 0.833333V18.3333C0 19.2536 0.748778 20 1.67208 20H25.9172C26.3791 20 26.7533 19.6271 26.7533 19.1667V18.3333C26.7533 17.8729 26.3791 17.5 25.9172 17.5ZM5.68507 15H6.85552C7.18994 15 7.52435 14.6667 7.52435 14.3333V10.6667C7.52435 10.3333 7.18994 10 6.85552 10H5.68507C5.35065 10 5.01624 10.3333 5.01624 10.6667V14.3333C5.01624 14.6667 5.35065 15 5.68507 15Z"
                                            data-v-021c25d6></path>
                                    </g>
                                </svg>
                            </div>

                            <!--Symbol(fst row 2nd column)-->
                            <div class="column step1-table__amount-partner col-6 col-lg-6" data-v-49591a1a data-v-8a74673e>
                                <span class="step1-table-amount step1-table__amount best-rate" data-v-9c9eb06c data-v-8a74673e>
                                    <p class="step1-table-amount" data-v-9c9eb06c data-v-8a74673e style="font-size: 18px; color:white;"
                                        id="foxexchangeDefault1_id"><i class="fa fa-spinner" *ngIf="load==0"></i>
                                        <b *ngIf="load==1">
                                            {{foxexchangeCalVal}}</b> </p>
                                </span>

                                <!--Exchanger(fst row 3rd column)-->
                                <h3 class="step1-table__partner" data-v-49591a1a data-v-8a74673e>
                                    fox.exchange
                                </h3>
                                <div class="step1-table__trustpilot-stars" data-v-49591a1a data-v-8a74673e>

                                </div>
                            </div>

                            <!-- 4th column-->
                            <div class=" step1-table__trustpilot d-f-c col-2 col-lg-2" data-v-49591a1a data-v-8a74673e>
                                <img src="../../assets/Final_logo/fox_exchange.png" alt="" padding-left="2" height="100%" width="100%" style="object-fit: contain;">

                            </div>
                            <!-- (button)5th column-->
                            <div class="column d-fcc col-4 col-lg-3" data-v-49591a1a data-v-8a74673e>
                                <button type="button" (click)="submitExchange('foxexchange')" *ngIf="foxexchangeEnDis == 1" class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                                <button type="button" (click)="submitExchange('foxexchange')" *ngIf="foxexchangeEnDis == 0" disabled class="button step1-table__button button_sm button_primary best-rate-button" data-v-700b2666 data-v-8a74673e>
                                    Exchange
                                </button>
                            </div>
                        </div>
                        <!------------------------------------------------------------------------------------>
                    </div>
                </div>
            </div>
        </div>

        <!-------------simpleswap table-------------------->
        <!-- </div> -->

    </div>
    <!-- banner-block -->
    <!-- <app-app-toast aria-live="polite" aria-atomic="true"></app-app-toast> -->


    <footer class="footer">
        <app-footer></app-footer>
    </footer>
    <script src="./assets/js/jquery-1.12.4.min.js"></script>
    <script src="./assets/js/popper.min.js"></script>
    <script src="./assets/js/bootstrap.min.js"></script>
    <script src="./assets/js/slick.min.js"></script>
    <script src="./assets/js/jquery.peity.min.js"></script>
    <script src="./assets/js/jquery.slimscroll.min.js"></script>
    <script src="./js/custom.js"></script>
</body>

</html>