<footer class="footer">
    <div class="footer-bottom">
        <div class="container">
            <div class="footer-bottom-wrap">
                <div class="footer-logo">
                    <a href="#">
                        <img src="assets/COTOEX Logo_white and orange.png" alt="img" class="img-responsive" height="50" width="80" />
                    </a>
                </div>
                <!-- <label style="color: white; margin-left:-920px;">
            </label> -->
                <div class="col-lg-2 col-md-3">
                    <div class="footer-info-list">
                        <ul class="social-style-two">
                            <li>
                                <a href="https://www.facebook.com">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com">
                                    <i class="fab fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.github.com">
                                    <i class="fab fa-github"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com">
                                    <i class="fab fa-linkedin-in"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="footer-info">
                    <a style="cursor: pointer; color: white; " data-toggle="modal" data-target="#myModal2">
                        Terms of Use
                    </a>&nbsp;&nbsp;

                    <a style="cursor: pointer; color: white;" data-toggle="modal" data-target="#myModal3">
                        Privacy Policy
                    </a>&nbsp;&nbsp;

                    <a style="cursor: pointer; color: white;" data-toggle="modal" data-target="#myModal4">
                        FAQ
                    </a>

                    <div class="modal" id="myModal2">
                        <div class="modal-dialog modal-lg modal-dialog-scrollable">
                            <div class="modal-content">

                                <!-- Modal Header -->
                                <div class="modal-header">
                                    <h4 class="modal-title"><b>Terms & Conditions</b></h4>
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                </div>

                                <!-- Modal body -->
                                <div class="modal-body">
                                    <ol>
                                        <li>This Terms of Use define the relation with You (“User” or “You”) as you interact with our Services which constitute each and collectively technology platform integrated in website cotoex.com (“Website”), associated
                                            application program interface, mobile applications, and establish the entire agreement and understanding of use of any of our Services.</li>
                                        <li>The Services are operated by BighBull Technosoft LLP.</li>
                                        <li>If You do not agree to be bound by these Terms of Use, do not access or use the Services.</li>
                                        <li>We reserve the right to change this Terms of Use at any time without notice, so please check it regularly. Your continued use of the Services constitutes acceptance of any changes which we might undertake to these
                                            Terms of Use from time to time subject to no prior notification.</li>
                                        <li>You hereby accept that electronic communication is the only way of communication adopted hereby subject to these Terms of Use and your use of the Services. That all notifications and documents will be sent to your
                                            email address and you should seek to send any notifications and documents to us by the similar means.</li>
                                        <li>Services may incorporate or require the use of third party products and services. Your use of such third parties products and services will be subject to the respective third parties terms and conditions and privacy
                                            policies, which You agree is your responsibility to regularly review from time to time to determine their acceptability to You before You use such third party products and services. For the avoidance of doubt,
                                            all info, token or digital currency swap and exchange functions found on or linked to the Services are provided “as is” received from third parties, and are not our products, as we do not process such swaps
                                            or exchanges, nor are we custodian to any form of e-money, stored value, digital coins or tokens.
                                        </li>
                                    </ol>

                                    <h4><b>No investment advice disclaimer</b></h4>
                                    <p>
                                        No information, write-ups, listings, prices, events, ICO data, photographs, pictures, graphs, charts, articles, news updates, budgets, forecasts, token swap market values, project information, testimonials, status, team information of any company, advertisements,
                                        data, analysis, reports, media files, APIs and other content on the website and the services marketing materials, promotional updates, API updates, support patches, messages (via any channels), or other emails which
                                        you may have viewed, subscribed to or downloaded via the Services, constitutes advice of any kind, including but not limited to financial advice, trading advice, investment advice, insurance advice, legal advice
                                        or any other form of advice for which a license to provide such advice may be required under applicable law.
                                    </p>

                                    <h4><b>Feedback & Contact</b></h4>
                                    <p>
                                        If you have any questions relating to these Terms & Conditions, Your rights and obligations arising from these Terms of Use and/or your use of the website and the service, or any other matter, please contact us at <b>techteam@cotoex.com.</b>
                                    </p>
                                </div>

                                <!-- Modal footer -->
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal" id="myModal3">
                        <div class="modal-dialog modal-lg modal-dialog-scrollable">
                            <div class="modal-content">

                                <!-- Modal Header -->
                                <div class="modal-header">
                                    <h4 class="modal-title"><b>Privacy Policy</b></h4>
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                </div>

                                <!-- Modal body -->
                                <div class="modal-body">
                                    <p>
                                        BighBull respects your privacy and is committed to protecting your personal data. This privacy policy aims to give you information on how we collect and process any personal data and how we look after your personal data when you visit our website (regardless
                                        of where you visit it from) and tell you about your privacy rights and how the law protects you. Please read this Privacy Policy carefully as it explains our practices regarding your personal data and how we will
                                        treat it, and the basis on which any personal data will be processed by us. References in this Privacy Policy and on our website to “we”, “our” or “us” are references to SwapSpace. References to “you” and “your”
                                        means each person who interacts with us, uses our website or the products and services we provide.
                                        <br>
                                    </p>
                                    <p>
                                        By accessing our website and services, or otherwise howsoever providing us with your personal data, you are agreeing to our collection of your information pursuant to this Privacy Policy. Should you disagree with any clause hereby stated, please immediately
                                        cease your access to, participation in, and use of our Website, and our Services. We undertake measures to implement advanced data protection policies and procedures and to update them from time to time for the
                                        purpose of safeguarding your data. Your data is protected by the legal, administrative and technical measures that we take to ensure the privacy, integrity and accessibility of data. To prevent security incidents
                                        with your data, we use a mixed organizational and technical approach based on the results of risk assessment. We will not divulge any of your private information unless you approve in writing such disclosure or
                                        unless such disclosure is required under applicable law or is required in order to verify your identity.
                                    </p>

                                    <h4><b>Cookies</b></h4>
                                    <p>
                                        We and our service providers collect information about your use of our website from cookies. A cookie is a small file which asks permission to be placed on your computer's hard drive. Once it is it helps analyse website traffic or lets us know when you
                                        visit a particular site. Cookies allow website applications to respond to you as an individual. The website application can tailor its operations to your needs, likes, and dislikes by gathering and remembering information
                                        about your preferences. We use traffic log cookies to identify which of our website pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to customer
                                        needs. We only use this information for statistical analysis purposes and then the data is removed from our system.
                                    </p>
                                    <p>
                                        Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to
                                        share with us. Any sensitive information in these cookies is encoded so that only we can interpret the information stored on them. The cookies are placed for the following purposes: Recognize You as a BighBull User,
                                        Collect information about your computer to mitigate risk and help prevent fraud, Customize your experience, content, and advertising, and Measure promotional effectiveness. Further, use of cookies and other technologies
                                        may allow us and third parties to collect information about user browsing activities over time and across different websites following use of service. You can choose to accept or decline cookies. Most web browsers
                                        automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
                                    </p>

                                    <h4><b>INFORMATION WE COLLECT FROM YOU</b></h4>
                                    <p>
                                        The information that we may collect about you through the Services broadly falls into the following categories: Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser
                                        plug-in type and versions, operating system and platform, and other technologies on the devices you use to access the Website and use of cookies stored on your device. Usage Data includes information about how you
                                        use the Website, products and services, number of complaints, number of requests filed and IP history.</p>
                                    <p>
                                        Marketing and Communication Data includes your preferences in receiving marketing from us and your communication preferences. Data in KYC (Know your customer) includes identity document information, including copies of recent dated Utility Bills, Identity
                                        Card, Passport, and/or Driver’s License. Location Data includes details on your actual location when interacting with our Website (for example, a set of parameters that determine regional settings of your interface,
                                        namely residency country, time zone, and the interface language). Aggregated Data includes statistical or demographic data for any purpose. Such data can be derived from your data but may not be considered personal
                                        data in law as it will not directly or indirectly reveal your identity. We may also ask for mobile/phone verification from user while creating transactions for some exchanges and store this information to provide
                                        a better experience to our users. Phone numbers that end users provide for authentication will be sent and stored by third party phone verification services to improve their spam and abuse prevention.
                                    </p>

                                    <h4><b>Transferring personal data</b></h4>
                                    <p>
                                        This may include countries that do not have data protection laws which are as strong as those in IN.<br> We will take the steps required under the Data Protection Legislation to ensure that your information is appropriately
                                        protected. You hereby agree and consent to the aforesaid transfer, on the understanding that the recipients of such Personal Data are also subject to applicable laws or contractual obligations.
                                    </p>

                                    <h4><b>Changes to privacy policy</b></h4>
                                    <p>
                                        Any changes we may make to our Privacy Policy in the future will be posted on this page and, where we consider it appropriate, notified to you by email. Please check back frequently to see any updates or changes to our Privacy Policy.
                                    </p>
                                </div>

                                <!-- Modal footer -->
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal" id="myModal4">
                        <div class="modal-dialog modal-lg modal-dialog-scrollable">
                            <div class="modal-content">

                                <!-- Modal Header -->
                                <div class="modal-header">
                                    <h4 class="modal-title">Frequently Asked Questions(F.A.Q)</h4>
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                </div>

                                <!-- Modal body -->
                                <div class="modal-body">

                                    <h3 style="color: rgb(4, 139, 4);">1. What is BighBull?</h3>
                                    <p>
                                        BIGH Bull Mission is to be a Single-stop Reliable and Advanced trading Platform for all Crypto, Forex & Stock Traders.
                                    </p>

                                    <h3 style="color: rgb(4, 139, 4);">2. How does BighBull works?</h3>
                                    <p>
                                        We will provide real world traders to trade in low transaction fees and swift liquidity. You don't need to manually copy trades, use BIGH Bull Copy Trading Solution and mirror your trades instantly across unlimited accounts by using different brokers.
                                    </p>

                                    <h3 style="color: rgb(4, 139, 4);">3. Do I need to register to use BighBull?
                                    </h3>
                                    <p>
                                        No, you don’t have to create an account or provide any personal info to perform action.
                                    </p>

                                    <h3 style="color: rgb(4, 139, 4);">4. Is there any refund/cancellation policy?
                                    </h3>
                                    <p>
                                        No, we don't provide any refund or cancellation.
                                    </p>

                                    <!-- Modal footer -->
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copyright-text">
                    © 2021 <a href="#">Cotoex</a>. All Rights Reserved
                    <!-- <span style="padding-left: 25px;"> beta version </span> -->
                </div>
            </div>
        </div>
    </div>

    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
    <!-- <ejs-toast></ejs-toast> -->