import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { WindowRefService } from '../services/razorpay_service/window-ref.service';
import Swal from 'sweetalert2';
import { AppToastServiceService } from '../services/app-toast-service.service';
import { environment } from '../../environments/environment'

const binanceAPI = environment.binance.baseapi;
const binanceWSApi = environment.binance.websocket;
const swapspaceAPI = environment.swapspace.baseapi;
const swapspaceAPIkey = environment.swapspace.APIkey;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})


export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {


  @ViewChild('valueId') usernameElement: ElementRef;

  selectForm: FormGroup;

  ws = new WebSocket(`${binanceWSApi}/ethusdt@kline_5m`);
  ws1 = new WebSocket(`${binanceWSApi}/bnbusdt@kline_5m`);
  ws2 = new WebSocket(`${binanceWSApi}/btcusdt@kline_5m`);
  ws3 = new WebSocket(`${binanceWSApi}/trxusdt@kline_5m`);

  stockPriceElement = document.getElementById('stock-price');
  stockPriceElement1 = document.getElementById('stock-price1');
  stockPriceElement2 = document.getElementById('stock-price2');
  stockPriceElement3 = document.getElementById('stock-price3');

  sign = '$';
  mySelect: any;
  lastPrice = null;
  stockObject;
  price: any;
  firstselect: any;
  secondselect: any;

  lastPrice1 = null;
  stockObject1;
  price1: any;

  lastPrice2 = null;
  stockObject2;
  price2: any;

  lastPrice3 = null;
  stockObject3;
  price3: any;

  defaultVal = 1;
  defaultVal2;

  getCoinData_A: any = [];
  getCoinData_B: any = [];

  toLower1: string;
  toLower2: string;
  binanceVal: any;
  binanceVal1: any;
  closeResult: any = '';
  mlist: any = [];
  mlistB: any = [];
  name: string;
  name2: string;
  hideButton: any = 1;
  bigb_bnb: any;
  usdConversion: any;
  x: number;
  disableBuyBigh: any;
  currencyPrice: any;
  INRRate: any;
  BIGBPrice: number;

  minRateBigb: any;
  currencyPrice1: any;


  constructor(private route: Router, private mysql: MysqlserviceService, private http: HttpClient,
    private toastService: AppToastServiceService, private fb: FormBuilder, private modalService: NgbModal, private winRef: WindowRefService) {
  }
  ngOnDestroy(): void {

  }

  ngAfterViewInit() {
    //--------------------get all currencies----------------
    this.http.get(`${swapspaceAPI}/currencies`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: swapspaceAPIkey
      }
    }).subscribe((a: any) => {
      a.forEach(b => {
        var abc = b.code
        this.getCoinData_A.push(abc)
        this.getCoinData_B.push(abc)
      })
    }, (e) => {
      Swal.fire({
        title: 'Error',
        text: `Something went wrong, Try again later`,
        icon: 'error',
        confirmButtonText: 'Ok',
        width: '400'
      })
    }, () => {
      this.getCoinData_B.push('bigb')
      this.getCoinData_A.forEach(a => {
        this.mlist.push(a)
      })
      this.getCoinData_B.forEach(a => {
        this.mlistB.push(a)
      })
    })

    this.stockPriceElement = document.getElementById('stock-price')
    this.stockPriceElement1 = document.getElementById('stock-price1')
    this.stockPriceElement2 = document.getElementById('stock-price2');
    this.stockPriceElement3 = document.getElementById('stock-price3');

    this.ws.onmessage = (event) => {
      this.stockObject = JSON.parse(event.data);
      this.price = parseFloat(this.stockObject.k.c);
      this.stockPriceElement.innerHTML = parseFloat(this.stockObject.k.c).toFixed(2);
      this.stockPriceElement.style.color = !this.lastPrice || this.lastPrice === this.price ? 'white' : this.price > this.lastPrice ? 'green' : 'red';
      this.lastPrice = this.price;

      this.ws1.onmessage = (event) => {
        this.stockObject1 = JSON.parse(event.data);
        this.price1 = parseFloat(this.stockObject1.k.c);
        this.stockPriceElement1.innerText = parseFloat(this.stockObject1.k.c).toFixed(2);
        this.stockPriceElement1.style.color = !this.lastPrice1 || this.lastPrice1 === this.price1 ? 'white' : this.price1 > this.lastPrice1 ? 'green' : 'red';
        this.lastPrice1 = this.price1;

        this.ws3.onmessage = (event) => {
          this.stockObject3 = JSON.parse(event.data);
          this.price3 = parseFloat(this.stockObject3.k.c);
          this.stockPriceElement3.innerText = parseFloat(this.stockObject3.k.c).toFixed(4);
          this.stockPriceElement3.style.color = !this.lastPrice3 || this.lastPrice3 === this.price3 ? 'white' : this.price3 > this.lastPrice3 ? 'green' : 'red';
          this.lastPrice3 = this.price3;
        }

        this.ws2.onmessage = (event) => {
          this.stockObject2 = JSON.parse(event.data);
          this.price2 = parseFloat(this.stockObject2.k.c);
          this.stockPriceElement2.innerText = parseFloat(this.stockObject2.k.c).toFixed(2);
          this.stockPriceElement2.style.color = !this.lastPrice2 || this.lastPrice2 === this.price2 ? 'white' : this.price2 > this.lastPrice2 ? 'green' : 'red';
          this.lastPrice2 = this.price2;

          if (this.secondselect != 'BIGB') {
            this.binanceExchanger();
          }
        }
      }
    }
  }

  open(content) {
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  open2(content) {
    // console.log("content2" + this.secondselect)
    this.modalService.open(content,
      { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult =
          `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  ngOnInit() {
    // this.toastService.showInfo("Testing Version", "Warning");

    // this.timer();
    this.firstselect = 'BTC';
    this.secondselect = 'ETH';

    this.selectForm = this.fb.group({
      selectControl1: ['BTC'],
      selectControl2: ['ETH']
    });

  }

  // timer() {
  //   var countDownDate = new Date(`december  25, 2021 12:00:00 PDT`).getTime();
  //   this.x = setInterval(function () {
  //     var now = new Date().getTime();
  //     // Find the distance between now and the count down date
  //     var distance = countDownDate - now;
  //     // console.log('distance'+distance)

  //     // Time calculations for days, hours, minutes and seconds
  //     this.d = Math.floor(distance / (1000 * 60 * 60 * 24));
  //     this.h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //     this.m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //     this.s = Math.floor((distance % (1000 * 60)) / 1000);
  //     if (document.getElementById("demo2") != null) {
  //       document.getElementById("demo2").innerHTML = "( " + this.d + "d " + this.h + "h "
  //         + this.m + "m " + this.s + "s " + ")";
  //     }
  //     // Output the result in an element with id="demo"


  //     // If the count down is over, write some text 
  //     // if (dE < 0) {
  //     //   clearInterval(this.x);
  //     //   document.getElementById("demo").innerHTML = "EXPIRED";
  //     // }
  //   });
  // }

  search() { // for first search box 
    this.getCoinData_A = []
    //push mlist(all coins data) to getCoinData_A array
    this.mlist.forEach(a => {
      this.getCoinData_A.push(a)
      return a
    })
    if (this.secondselect == "BIGB") {
      this.firstselect = 'INR'
      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      this.hideButton == 1;
      if (this.firstselect == 'inr' || this.firstselect == 'bnb' || this.firstselect == 'btc' || this.firstselect == 'trx' || this.firstselect == 'eth') {
        this.getCoinData_A = []
        // this.getCoinData_A.push('inr')
        this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      }

      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      this.bigb_Conversion(this.firstselect);
    }
    if (this.name.toLocaleLowerCase() == '' && this.secondselect == "BIGB") {   //if we not type anything in search box then also all coin list will show
      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
    }
    else if (this.name.toLocaleLowerCase() == '') {
      this.mlist.forEach(a => {
        this.getCoinData_A.push(a)
        return a
      })
    }
    // match typed coin in search list
    this.getCoinData_A = this.getCoinData_A.filter((res: any) => {
      return res.match(this.name.toLocaleLowerCase())
    });
  }

  search2() {
    this.getCoinData_B = []
    //mlistB => all coin data + bigb coin
    // and we push mlistB in getCoinData_B array
    this.mlistB.forEach(a => {
      this.getCoinData_B.push(a)
      return a
    })

    // if second select is BIGB then first select options are only 'inr', 'bnb', 'btc', 'trx', 'eth'
    if (this.secondselect == 'BIGB') {
      this.firstselect = 'INR'
      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      this.hideButton == 1;
    }
    if (this.name2.toLocaleLowerCase() == '') {//if we not type anything in search box then also all coin list will show
      this.mlistB.forEach(a => {
        this.getCoinData_B.push(a)
        return a
      })
    }
    //match typed coin in search list
    this.getCoinData_B = this.getCoinData_B.filter((res: any) => {
      return res.match(this.name2.toLocaleLowerCase())
    });
  }

  //for selcting the coin in search modal box
  selectclick(data1: any) {
    this.mlist.forEach(a => {
      this.getCoinData_A.push(a)
    })
    this.firstselect = data1.toUpperCase();
    this.modalService.dismissAll()
    this.name = '';
    if (this.secondselect == "BIGB") {
      if (data1 == 'inr' || data1 == 'bnb' || data1 == 'btc' || data1 == 'trx' || data1 == 'eth') {
        this.getCoinData_A = []
        // this.getCoinData_A.push('inr')
        this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      }
      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      this.bigb_Conversion(this.firstselect);
    }
    if (this.firstselect == this.secondselect) {
      if (this.firstselect != 'BTC') {
        this.secondselect = 'BTC'
        this.binanceExchanger();
      }
      else {
        this.secondselect = 'ETH'
        this.binanceExchanger();
      }
    }
  }

  selectclick2(data1: any) {
    this.mlistB.forEach(a => {
      this.getCoinData_B.push(a)
    })
    this.secondselect = data1.toUpperCase();
    this.modalService.dismissAll()
    this.name2 = ''

    if (data1 == 'bigb') {
      this.firstselect = 'INR'
      this.getCoinData_A = []
      // this.getCoinData_A.push('inr')
      this.getCoinData_A.push('inr', 'bnb', 'btc', 'trx', 'eth')
      this.hideButton = 0;
      this.bigb_Conversion(data1);
    }
    else {
      this.hideButton = 1;
      if (this.firstselect == 'INR') {
        this.firstselect = 'ETH';
      }
      this.getCoinData_A = []
      this.getCoinData_B = []

      //api for get all currencies
      this.http.get(`${swapspaceAPI}/currencies`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: swapspaceAPIkey
        }
      }).subscribe((a: any) => {
        a.forEach(b => {
          this.getCoinData_A.push(b.code)
          this.getCoinData_B.push(b.code)
        })
      }, (e) => {
        Swal.fire({
          title: 'Error',
          text: `Something went wrong, Try again later`,
          icon: 'error',
          confirmButtonText: 'Ok',
          width: '400'
        })
      }, () => {
        this.getCoinData_A.forEach(a => {
          this.mlist.push(a)
        })
        this.getCoinData_B.forEach(a => {
          this.mlistB.push(a)
        })
      })
      if (this.firstselect == this.secondselect) {
        if (this.firstselect != 'ETH') {
          this.secondselect = 'ETH'
          this.binanceExchanger();
        }
        else {
          this.secondselect = 'BTC'
          this.binanceExchanger();
        }
      }
    }
  }

  myFunction() {
    console.log(this.secondselect)
    if (this.secondselect != 'BIGB') {
      this.binanceExchanger()
    }
    else {
      this.bigb_Conversion(this.firstselect);
    }
  }

  binanceExchanger() {
    this.binanceVal = null;
    //for get value of coin in $
    this.http.get(`${binanceAPI}/avgPrice?symbol=${this.firstselect}USDT`).subscribe(b => {
      this.binanceVal = b.valueOf()
      this.binanceVal = this.binanceVal.price

      this.http.get(`${binanceAPI}/avgPrice?symbol=${this.secondselect}USDT`).subscribe(b => {
        this.binanceVal1 = b.valueOf()
        this.binanceVal1 = this.binanceVal1.price
      }, (e) => {
        Swal.fire({
          title: 'Error',
          text: `Something went wrong, Try again later`,
          icon: 'error',
          confirmButtonText: 'Ok',
          width: '400'
        })
      }, () => {
        // get amont of first coin  => second coin price
        var coinVal = this.binanceVal * this.usernameElement.nativeElement.value;
        var dd = coinVal / this.binanceVal1;
        this.defaultVal2 = dd.toFixed(6);
      })
    })
  }

  viewOffer() {
    // this.route.navigate([`/view-table/${this.firstselect}/${this.secondselect}`]);
    this.route.navigateByUrl('/view-table', { state: { drp_1: this.firstselect, drp_2: this.secondselect } });

    // this.route.navigateByUrl(`/view-table/${this.firstselect}/${this.secondselect}`);
  }

  bigbExchange() {
    if (this.firstselect == 'INR') {
      if (this.disableBuyBigh != ' ') {
        console.log('dashboard drp1 : ', this.firstselect, '  amount : ', this.usernameElement.nativeElement.value)

        // this.route.navigateByUrl(`/bigb-step2/${this.firstselect}/${this.usernameElement.nativeElement.value}`);
        this.route.navigateByUrl('/bigb-step2', { state: { drp_1: this.firstselect, amt: this.usernameElement.nativeElement.value } });
      }
      // Ippopay.open('order_JeR1G3xl$H','pk_live_Fdxzg7BTPgSR');
      // this.createRzpayOrder(this.usernameElement.nativeElement.value);
    }
    else {
      if (this.disableBuyBigh != ' ') {
        // this.route.navigateByUrl(`/bigb-step2/${this.firstselect}/${this.usernameElement.nativeElement.value}`);
        this.route.navigateByUrl('/bigb-step2', { state: { drp_1: this.firstselect, amt: this.usernameElement.nativeElement.value } });
      }
    }
  }

  bigb_Conversion(data) {
    this.disableBuyBigh = ' '
    this.toLower1 = String(data).toUpperCase()
    console.log('firstSelect', this.firstselect)

    // this.defaultVal2 = 'Not Available';

    if (this.firstselect == 'INR') {
      var bigbPrice;
      this.mysql.getBigbPrice().subscribe((a: any) => {
        this.mysql.getMinRateBigb().subscribe((minVal: any) => {

          console.log('min value', JSON.stringify)

          console.log('USD price' + 'inr : ' + a.INRPrice + 'bigb : ' + a.price + '\n input: ', this.usernameElement.nativeElement.value)
          bigbPrice = a.INRPrice;
          console.log('bigb to inr price 1 : ' + bigbPrice)
          if (this.usernameElement.nativeElement.value < minVal.minInr) {   //min amount should be 1000
            this.disableBuyBigh = ' '
            this.defaultVal2 = `min: ${minVal.minInr}`;
          } else if (this.usernameElement.nativeElement.value > minVal.maxInr) {
            this.disableBuyBigh = ' '
            this.defaultVal2 = `max: ${minVal.maxInr}`;
          }
          else {
            this.disableBuyBigh = 'notDisable'
            this.defaultVal2 = this.usernameElement.nativeElement.value / bigbPrice
            console.log('default price : ' + this.defaultVal2)
          }
        }, (e) => {
          Swal.fire({
            title: 'Error',
            text: `Something went wrong, Try again later`,
            icon: 'error',
            confirmButtonText: 'Ok',
            width: '400'
          })
        }, () => {
        })
      }, (e) => {
        Swal.fire({
          title: 'Error',
          text: `Something went wrong, Try again later`,
          icon: 'error',
          confirmButtonText: 'Ok',
          width: '400'
        })
      })

    }
    else {

      this.http.get(`${binanceAPI}/avgPrice?symbol=${this.firstselect}USDT`).subscribe(b1 => {
        this.currencyPrice1 = b1.valueOf()
        this.currencyPrice = this.currencyPrice1.price * this.usernameElement.nativeElement.value;
        console.log('currency price', JSON.stringify(b1))
      }, (e) => {
        Swal.fire({
          title: 'Error',
          text: `Something went wrong, Try again later`,
          icon: 'error',
          confirmButtonText: 'Ok',
          width: '400'
        })
      }, () => {
        this.mysql.getBigbPrice().subscribe((b: any) => {
          this.mysql.minmaxCoinpayment(this.firstselect).subscribe((minCP: any) => {
            console.log('min cp : ', minCP)
            console.log('\nb.INRPrice : ', b.INRPrice)

            this.BIGBPrice = b.dollarPrice;

            var calculate2 = this.currencyPrice * this.BIGBPrice 

            var minCoinp = minCP.min / this.BIGBPrice
            var otherConversion = minCoinp / this.currencyPrice1.price;
            console.log('minCoinp : ',minCoinp, '  minCP.min   :',minCP.min ,  'otherConversion : ', otherConversion)

            if (this.usernameElement.nativeElement.value < otherConversion) {
              this.disableBuyBigh = ' '
              this.defaultVal2 = `min : ${otherConversion}`
            } else {
              this.disableBuyBigh = 'notDisable'
              this.defaultVal2 = calculate2
            }
            // if
            //   console.log('hi 1')
            //   if (minAmtBigb < otherConversion) {
            //     console.log('hi 2')
            //     this.disableBuyBigh = ' '
            //     this.defaultVal2 = `min : ${otherConversion}`
            //   } else if (minAmtBigb > otherConversion) {
            //     console.log('hi 3')
            //     this.disableBuyBigh = ' '
            //     this.defaultVal2 = `min : ${minAmtBigb}`
            //   }
            // } else {
            //   console.log('else 2')
            //   this.disableBuyBigh = 'notDisable'
            //   this.defaultVal2 = calculate2
            // }

            console.log('CP min : ', minCoinp, ' calculated : ', calculate2)
            // CP min :  0.00300000  calculate3 :  0.0002710280183059834 cal 4 :  0.000004573597808913469  calculate 2 :  218646.24782946665

            console.log(' \ncalculate2 : ', calculate2)
          }, (e) => {
            Swal.fire({
              title: 'Error',
              text: `Something went wrong, Try again later`,
              icon: 'error',
              confirmButtonText: 'Ok',
              width: '400'
            })
          }, () => {
            console.log("BIGBPRICE in INR : ", this.BIGBPrice, "  converted amt  : ", this.defaultVal2, ' fromcurrency price : ', this.currencyPrice)
          })

        }, (e) => {

        })
      })
    }
  }

}
