import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MysqlserviceService } from '../services/mysqlService/mysqlservice.service';
import Swal from 'sweetalert2'
import { WindowRefService } from '../services/razorpay_service/window-ref.service';
import { environment } from '../../environments/environment'

const binanceAPI = environment.binance.baseapi;

declare var Ippopay: any;

@Component({
  selector: 'app-bigb-exchange-step2',
  templateUrl: './bigb-exchange-step2.component.html',
  styleUrls: ['./bigb-exchange-step2.component.css']
})
export class BigbExchangeStep2Component implements OnInit {

  transaction_data: any = [];
  table_id: any;
  drp_1: any;
  drp_2: any = 'BIGB';
  ippopayRes: any = [];
  inr_transaction_data: any = [];

  amount: any;
  convertedAmt: any;
  generated_id: any;
  @ViewChild('dest_addr') dest_addrElement: ElementRef;
  @ViewChild('refund_addr') refund_addrElement: ElementRef;
  address: any;
  check: any;
  user_id: any;
  transArr: any;
  toLower1: string;
  toLower2: string;
  ipAddress: any;
  loader: any = 1;

  fname: any;
  emailId: any;

  usdConversion: any = 0;
  ipopayOrderId: any;

  BIGBPrice: any;
  bigbDollar: any;

  currencyPrice: any;
  currencyPrice1: any;

  state: any;

  constructor(private formm: FormBuilder, private http: HttpClient, private route: Router, private mysql: MysqlserviceService) {
    var today = new Date();
    window.scroll(0, 0);
    this.state = this.route.getCurrentNavigation().extras.state
    this.drp_1 = this.state.drp_1
    this.amount = this.state.amt
    console.log('bigb step 2 drp1 : ', this.drp_1, '  amount : ', this.amount)

    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        this.ipAddress = data.ip
      })

    const token = localStorage.getItem("SeesionUser")
    console.log("token" + token)
    this.user_id = token;
    // this.activatedRoute.params.subscribe(data => {
    //   this.drp_1 = String(data.drp_1)
    //   // this.drp_2 = String(data.drp_2)
    //   this.amount = String(data.amt)
    // });
    Ippopay.init();
  }

  recipient2 = this.formm.group({
    address: ['', [Validators.required, Validators.pattern(/^0x[a-fA-F0-9]{40}$/)]],
    check: ['', Validators.required]
  });

  public errMsg = {
    address: [
      { type: 'required', message: 'Please enter correct address' },
      { type: 'pattern', message: 'Please enter Bigb address' }
    ],
    check: [
      { type: 'required', message: 'Please accept the Terms of Policy' },
    ],
  }

  ngOnInit() {
    this.mysql.getUsers2(this.user_id).subscribe(a => {
      console.log('user details : ', JSON.stringify(a))
      this.emailId = a[0].email;
      this.fname = a[0].f_name;
    }, (e) => {
      Swal.fire({
        title: 'Error',
        text: `Something went wrong, try again later`,
        icon: 'error',
        confirmButtonText: 'OK',
        width: '400'
      })
    })

    var today = new Date();

    if (this.drp_1 == 'INR') {
      this.mysql.getBigbPrice().subscribe((b: any) => {
        this.mysql.getMinRateBigb().subscribe((minVal: any) => {
          this.BIGBPrice = b.INRPrice;
          this.bigbDollar = b.dollarPrice
          console.log(" Constructor BIGBPRICE : ", this.BIGBPrice)
          if (this.amount < minVal.minInr) {
            Swal.fire({
              title: 'Error',
              text: 'You need to select minimum Price',
              icon: 'error',
              confirmButtonText: 'Cancel',
              width: '400'
            })
            this.route.navigateByUrl(`/dashboard`)
          } else if (this.amount > minVal.maxInr) {
            Swal.fire({
              title: 'Error',
              text: 'You need to select amount price less than or equal to 99000',
              icon: 'error',
              confirmButtonText: 'Cancel',
              width: '400'
            })
            this.route.navigateByUrl(`/dashboard`)
          }
          else {
            this.convertedAmt = this.amount / this.BIGBPrice
          }
        }, (e) => {
          Swal.fire({
            title: 'Error',
            text: `Something went wrong, try again later`,
            icon: 'error',
            confirmButtonText: 'OK',
            width: '400'
          }).then(a => this.route.navigateByUrl(`/dashboard`))
        }, () => {
          console.log("BIGBPRICE : ", this.BIGBPrice)
        })
      }, (e) => {
        Swal.fire({
          title: 'Error',
          text: `Something went wrong, try again later`,
          icon: 'error',
          confirmButtonText: 'OK',
          width: '400'
        }).then(a => this.route.navigateByUrl(`/dashboard`))
      })
    }

    else {
      this.http.get(`${binanceAPI}/avgPrice?symbol=${this.drp_1}USDT`).subscribe(b1 => {
        this.currencyPrice1 = b1.valueOf()
        this.currencyPrice = this.currencyPrice1.price * this.amount;
        console.log('currency price', JSON.stringify(b1))
      }, (e) => {
        Swal.fire({
          title: 'Error',
          text: `Something went wrong, try again later`,
          icon: 'error',
          confirmButtonText: 'OK',
          width: '400'
        }).then(a => this.route.navigateByUrl(`/dashboard`))
      }, () => {
        var abc;
        this.mysql.getBigbPrice().subscribe((b: any) => {
          this.mysql.minmaxCoinpayment(abc).subscribe((minCP: any) => {
            console.log( '\nb.INRPrice : ', b.INRPrice)
            this.BIGBPrice = b.dollarPrice;

            var calculate2 = this.currencyPrice * this.BIGBPrice 

            var minCoinp = minCP.min / this.BIGBPrice
            var otherConversion = minCoinp / this.currencyPrice1.price;
            if (calculate2 < otherConversion) {
              Swal.fire({
                title: 'Error',
                text: 'You need to select minimum Price',
                icon: 'error',
                confirmButtonText: 'Cancel',
                width: '400'
              }).then(a => this.route.navigateByUrl(`/dashboard`))

            } else {
              // this.disableBuyBigh = 'notDisable'
              this.convertedAmt = calculate2
            }
            console.log(' \ncalculate2 : ', calculate2)
          }, (e) => {
            Swal.fire({
              title: 'Error',
              text: `Something went wrong, try again later`,
              icon: 'error',
              confirmButtonText: 'OK',
              width: '400'
            }).then(a => this.route.navigateByUrl(`/dashboard`))
          }, () => {
            console.log("BIGBPRICE in INR : ", this.BIGBPrice, "  converted amt  : ", this.convertedAmt, ' fromcurrency price : ', this.currencyPrice)
          })
        }, (e) => {
          Swal.fire({
            title: 'Error',
            text: `Something went wrong, try again later`,
            icon: 'error',
            confirmButtonText: 'OK',
            width: '400'
          }).then(a => this.route.navigateByUrl(`/dashboard`))
        })
      })
    }
  }

  gotoStep3() {
    this.clickedExchanger();
  }

  clickedExchanger() {
    var today = new Date();
    var trans_date = today.toISOString();
    this.loader = 0;
    if (this.drp_1 == 'INR') {
      console.log('details : ', this.user_id, ' amt : ', this.amount, ' date : ', trans_date, 'converted amt : ', this.convertedAmt)
      this.payWithIpopay(this.user_id, this.amount, this.convertedAmt, trans_date, this.mysql, this.route, this.fname, this.emailId, this.dest_addrElement.nativeElement.value, this.bigbDollar);
    }
    else {
      var email;
      this.mysql.getUserMail(this.user_id).subscribe(a => {
        email = a[0].email;
      })
      console.log('this.dest_addrElement.nativeElement.value', this.dest_addrElement.nativeElement.value)

      var today = new Date();
      var dataArr = {
        currency1: 'USD', // this.secondselect
        currency2: this.drp_1,
        amount: this.currencyPrice1.price * this.amount,
        mail : email
      }
      var sendAmount;
      this.mysql.createTransCoinpayment(dataArr).subscribe((a: any) => {
        console.log('createTransCoinpayment------' + JSON.stringify(a))
        console.log('checkinggg amountsss from :   ', this.amount, ' to :  ', this.convertedAmt)
        this.generated_id = a.txn_id
        sendAmount = a.amount;
      }, (e) => {
        Swal.fire({
          title: 'Error',
          text: `Something went wrong, try again later`,
          icon: 'error',
          confirmButtonText: 'OK',
          width: '400'
        })
      }, () => {
        this.transaction_data.push({
          user_id: this.user_id,
          id: this.generated_id,
          to_coin: 'BIGB',
          from_coin: this.drp_1,
          to_amount: this.convertedAmt,
          from_amount: sendAmount,
          transaction_date: today.toISOString(),
          transaction_status: ' ',
          bigb_status: ' ',
          userTrustWallet: this.dest_addrElement.nativeElement.value,
          bPriceDollar: this.BIGBPrice
        })
        console.log('bigb Price : ', this.BIGBPrice)
        this.mysql.addBigbTransactionDetails(this.transaction_data).subscribe(res => {
          console.log('bigb transaction posted------' + JSON.stringify(res))
        }, (e) => {
          console.log('bigb transaction posted error------' + JSON.stringify(e))
          Swal.fire({
            title: 'Error',
            text: `Something went wrong, try again later`,
            icon: 'error',
            confirmButtonText: 'OK',
            width: '400'
          })
        }, () => {
          this.mysql.getUserMail(this.user_id).subscribe(a => {
            this.mysql.sendMailTransac(a[0].email, this.generated_id, a[0].f_name).subscribe(a => {
              // this.route.navigateByUrl(`/bigb-step3/${this.generated_id}/${this.amount}/${this.convertedAmt}/${this.dest_addrElement.nativeElement.value}`)
              this.route.navigateByUrl('/bigb-step3', { state: { dest_addr: this.generated_id, amount: this.amount, convertedAmt: this.convertedAmt, user_addr: this.dest_addrElement.nativeElement.value } });

              // console.log('mail sent success')
            }, e => {
              Swal.fire({
                title: 'Error',
                text: `Something went wrong, try again later`,
                icon: 'error',
                confirmButtonText: 'OK',
                width: '400'
              })
              // console.log("error sending mail"+JSON.stringify(e))
            }, () => {

            })
          }, (e) => {
            Swal.fire({
              title: 'Error',
              text: `Something went wrong, try again later`,
              icon: 'error',
              confirmButtonText: 'OK',
              width: '400'
            })
          })
        })
      })
      console.log('user mail' + this.user_id)
    }
    // } else {

    // }
  }

  //  ---------------------------------------- ippopay Implementation ----------------------------------------------------


  payWithIpopay(user_id, from_amt, to_amt, transaction_date, mysql, route, fname, email, usertrustwalletaddress, bigbDollarPrice) {
    var transactionDataIpopay = []
    var responseOrderId;
    var transactionId;
    var fromAmt = Number(from_amt)
    var charge = fromAmt / 100;
    var fromAmtCal = Number(fromAmt + charge);
    console.log('fromAmtCal',fromAmtCal, 'charge : ',charge, ' from_amt : ',fromAmt)
    var today = new Date();
    var data = {
      fname: fname,
      email: email,
      amount: fromAmtCal
    }
    console.log('checking converted amount in ippopay : ', to_amt)
    this.mysql.ipopayCallFun(data).subscribe((response: any) => {
      responseOrderId = response.data.order.order_id

      console.log('ippopay response', JSON.stringify(this.ippopayRes) + 'order id : ', response.success)

      if (response.success == true) {
        console.log('hi')
        this.loader = 1;
        transactionDataIpopay.push({
          user_id: user_id,
          order_id: responseOrderId,
          transactionId: ' ',
          toAmt: to_amt,
          fromAmt: from_amt,
          transactionDate: transaction_date,
          transaction_status: ' ',
          paymentGateway: 'Ippopay',
          bigbSendStatus: ' ',
          usertrustwalletaddress: this.dest_addrElement.nativeElement.value, //trust address
          bPriceDollar: bigbDollarPrice
        })
        mysql.addBigbInrTransactionDetails(transactionDataIpopay).subscribe(a => {
          console.log("data store succesfully inrbigb" + JSON.stringify(a))
        }, (e) => {
          this.loader = 1;
          Swal.fire({
            title: 'Error',
            text: `Something went wrong, try again later`,
            icon: 'error',
            confirmButtonText: 'OK',
            width: '400'
          })
          console.log(" error data store inrbigb" + JSON.stringify(e))
        }, () => {
          this.loader = 1;

          console.log('order id before update' + responseOrderId)
          this.updateIppopayInfo(this.user_id, this.mysql, this.route, responseOrderId, usertrustwalletaddress, fromAmtCal)
          Ippopay.open(responseOrderId, 'pk_live_Fdxzg7BTPgSR')
        })
      }
      else if (response.success == false) {
        Swal.fire({
          title: 'Error',
          text: 'Something went wrong, Try again later',
          icon: 'error',
          confirmButtonText: 'Cancel',
          width: '400'
        })
        this.loader = 1;
      }
    }, (e) => {
      this.loader = 1;
      Swal.fire({
        title: 'Error',
        text: `Something went wrong, try again later`,
        icon: 'error',
        confirmButtonText: 'OK',
        width: '400'
      })
    }, () => {
    })
  }

  updateIppopayInfo(user_id, mysql, route, orderId, trustAddr, from_amt) {
    var today = new Date();
    var trans_date = today.toISOString();
    var transactionDataIpopay = [];
    const ippopayHandler = (function (a) {
      console.log('ippopayHandler', JSON.stringify(a), '\n data : ', a.data)
      if (a.data.status == 'success') {
        transactionDataIpopay.push({
          orderId: orderId,
          user_id: user_id,
          transactionId: a.data.transaction_no,
          transactionDate: trans_date,
          transaction_status: 'success'
        })
        mysql.updateBigbInrTransactionData(transactionDataIpopay).subscribe(a => {
          console.log("data updated succesfully inrbigb" + JSON.stringify(a))
          this.loader = 1;
          var datasendToken = {
            usertrustwalletaddress: trustAddr, //bigb addresss
            amountInr: from_amt //amount
          }
          mysql.sendtoken(datasendToken).subscribe(restoken => {
            console.log('send bigb token response : ', restoken.status)
            if (restoken.status == 'success') {
              var data2Sucess = {
                orderId: orderId,
                BigbStatus: 'success'
              }
              mysql.updateBigbInrBigbStatus(data2Sucess).subscribe(a => {
                console.log('status updated')
              }, (e) => {
                this.loader = 1;
                Swal.fire({
                  title: 'Error',
                  text: `Something went wrong, try again later`,
                  icon: 'error',
                  confirmButtonText: 'OK',
                  width: '400'
                })
                console.log('error updating success tokens status' + JSON.stringify(e))
              }, () => {
                route.navigateByUrl('/dashboard')
              })
            }
            if (restoken.status == 'failure') {
              var data2fail = {
                orderId: orderId,
                BigbStatus: 'failed'
              }
              mysql.updateBigbInrBigbStatus(data2fail).subscribe(a => {
                console.log('status updated')
              }, (e) => {
                this.loader = 1;
                Swal.fire({
                  title: 'Error',
                  text: `Something went wrong, try again later`,
                  icon: 'error',
                  confirmButtonText: 'OK',
                  width: '400'
                })
                console.log('error updating failed tokens status' + JSON.stringify(e))
              }, () => {
                route.navigateByUrl('/dashboard')
              })
            }
            //check all the cases of success and failure (done)
            //update bigbsend status in bigb_inr_table success if restoken is success (done)
            //update bigbsend status in bigb_inr_table fail if restoken is fail (done)
          }, (e: any) => {
            Swal.fire({
              title: 'Error',
              text: `Something went wrong, try again later`,
              icon: 'error',
              confirmButtonText: 'OK',
              width: '400'
            })
            this.loader = 1;
            console.log('error sending tokens' + JSON.stringify(e))
            // console.log('hiiiiiiii failed',e.headers.error.status)
            // if (e.headers.error.status == 'failure') {
            var data2fail = {
              orderId: orderId,
              BigbStatus: 'failed'
            }
            mysql.updateBigbInrBigbStatus(data2fail).subscribe(a => {
              console.log('status updated')
            }, (e) => {
              this.loader = 1;
              Swal.fire({
                title: 'Error',
                text: `Something went wrong, try again later`,
                icon: 'error',
                confirmButtonText: 'OK',
                width: '400'
              })
              console.log('error updating failed tokens status' + JSON.stringify(e))
            }, () => {
              route.navigateByUrl('/dashboard')
            })
          }, () => {
            console.log('hiii complete call send tokens')
          })

        }, (e) => {
          this.loader = 1;
          Swal.fire({
            title: 'Error',
            text: `Something went wrong, try again later`,
            icon: 'error',
            confirmButtonText: 'OK',
            width: '400'
          })

          console.log(" error data updated inrbigb" + JSON.stringify(e))
        }, () => {
        })
        console.log('success constructor', JSON.stringify(a.data) + '  trans data : ' + JSON.stringify(transactionDataIpopay))
      }
      if (a.data.status == 'failure') {
        this.loader = 1;
      }
      if (a.data.status == 'closed') {
        this.loader = 1;
      }
    });
    window.addEventListener('message', ippopayHandler);
  }
}


















// var dataArr = {
//   currency1 : 'TRX', // this.secondselect
//   currency2 : this.firstselect,
//   amount : this.usernameElement.nativeElement.value
// }

// this.mysql.createTransCoinpayment(dataArr).subscribe(a => {
//   console.log('createTransCoinpayment------'+JSON.stringify(a))
// },(e)=>{

// },()=>{

// })